import React from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import { RootState } from '@/store';

import { TokenAddedToEntryProperties } from '@/ampli';
import PromoCardAirdrop from '@/components/molecules/promo-cards/components/promo-card-airdrop';
import PromoCardBoost from '@/components/molecules/promo-cards/components/promo-card-boost';
import PromoCardNoVultures from '@/components/molecules/promo-cards/components/promo-card-no-vultures';
import PromoCardPowerUp from '@/components/molecules/promo-cards/components/promo-card-power-up';
import PromoCardReferral from '@/components/molecules/promo-cards/components/promo-card-referral';
import { SportId } from '@/interfaces/types';

type PromoCard = { expiresAt: string; el: JSX.Element };

const useGetPromoCards = ({
  filterBySport,
  handleCloseModal,
  location,
  sportId,
}: {
  filterBySport?: boolean;
  handleCloseModal?: () => void;
  location: TokenAddedToEntryProperties['location'];
  sportId?: SportId;
}): JSX.Element[] => {
  const airdrops = useSelector((state: RootState) => state.pickEmOverUnder.featuredLobby.airdrops);
  const constructedAppearances = useSelector(
    (state: RootState) => state.pickEmOverUnder.featuredLobby.constructedAppearances
  );
  const constructedPromoAppearances = useSelector(
    (state: RootState) => state.pickEmOverUnder.featuredLobby.constructedPromoAppearances
  );
  const powerUps = useSelector((state: RootState) => state.pickEmOverUnder.featuredLobby.powerUps);
  const webAirdropsFeatureEnabled = useSelector(
    (state: RootState) => state.featureFlags.webAirdrops
  );
  const webNoVulturesFeatureEnabled = useSelector(
    (state: RootState) => state.featureFlags.webNoVultures
  );
  const webPowerUpsFeatureEnabled = useSelector(
    (state: RootState) => state.featureFlags.webPowerUps
  );

  const promoCards: PromoCard[] = [];

  // Show sport-specific boosts in sport lobbies and all boosts on homepage
  let filteredPromoAppearances = constructedPromoAppearances;
  if (filterBySport) {
    filteredPromoAppearances = constructedPromoAppearances.filter(
      (cPA) => cPA.sport.id.toLowerCase() === sportId.toLowerCase() || sportId === 'home'
    );
  }

  if (filteredPromoAppearances?.length) {
    filteredPromoAppearances.forEach((cPA) => {
      const cA = constructedAppearances[cPA.id];
      const gameScheduledAt = cA?.match?.scheduledAt ?? cA?.soloGame?.scheduledAt;
      const countdownExpiresAt = cA?.expiresAt ?? gameScheduledAt;
      const countdownIsExpired = countdownExpiresAt && dayjs(countdownExpiresAt).isBefore(dayjs());

      cPA.boostedLineIds.forEach((boostedLineId: string) => {
        promoCards.push({
          expiresAt: !countdownIsExpired ? countdownExpiresAt : null,
          el: (
            <PromoCardBoost
              key={boostedLineId}
              boostedLineId={boostedLineId}
              constructedAppearance={cA}
              countdownExpiresAt={!countdownIsExpired ? countdownExpiresAt : null}
              gameScheduledAt={gameScheduledAt}
            />
          ),
        });
      });
    });
  }

  if (powerUps?.length && webPowerUpsFeatureEnabled) {
    powerUps.forEach((pU) => {
      promoCards.push({
        expiresAt: pU.expiresAt,
        el: <PromoCardPowerUp key={pU.id} location={location} powerUp={pU} />,
      });
    });
  }

  if (airdrops?.length && webAirdropsFeatureEnabled) {
    airdrops.forEach((airdrop) => {
      promoCards.push({
        expiresAt: airdrop.expiresAt,
        el: <PromoCardAirdrop key={airdrop.id} airdrop={airdrop} />,
      });
    });
  }

  if (promoCards.length || webNoVulturesFeatureEnabled) {
    const sortedPromoCardEls = promoCards
      .sort((a, b) => {
        const currDate = dayjs(a.expiresAt);
        const prevDate = dayjs(b.expiresAt);

        if (currDate === prevDate) return 0;
        if (currDate.isBefore(prevDate)) return -1;
        return 1;
      })
      .map((promoCard) => promoCard.el);

    if (webNoVulturesFeatureEnabled) {
      sortedPromoCardEls.push(<PromoCardNoVultures key="noVultures" />);
    }

    sortedPromoCardEls.push(
      <PromoCardReferral key="referral" handleCloseModal={handleCloseModal} />
    );

    return sortedPromoCardEls;
  }

  return [];
};

export default useGetPromoCards;
