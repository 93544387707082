import React, { useState } from 'react';

import { changePasswordAuth0 as changePasswordAuth0Api } from '@/api/users';
import Button from '@/components/atoms/button';
import Icon from '@/components/atoms/icon';
import LinkWrapper from '@/components/atoms/link-wrapper';
import { HandleCloseModal, useModal } from '@/components/atoms/modal';
import ProgressBarStepped from '@/components/atoms/progress-bar-stepped';
import TextField from '@/components/atoms/text-field';
/* eslint-disable-next-line import/no-cycle */
import LoginModal from '@/components/molecules/login-modal';
import errorLogger from '@/utilities/errors/logger';
import { validEmail } from '@/utilities/helpers';

import styles from './styles.scss';

export interface ForgotPasswordFormProps {
  isModal?: boolean;
}

const ForgotPasswordForm = (props: ForgotPasswordFormProps): JSX.Element => {
  const openModal = useModal();
  const { isModal } = props;
  const [email, setEmail] = useState<string>('');
  const [emailSent, setEmailSent] = useState<boolean>(false);
  const [emailValid, setEmailValid] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<string | false>(false);

  const onEmailChange = ({ value }: { value: string }): void => {
    setEmail(value);
    setEmailValid(validEmail(value));
  };

  const onEmailBlur = () => {
    if (!emailValid) {
      setEmailError('Please enter a valid email address');
    } else {
      setEmailError(false);
    }
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    changePasswordAuth0Api({ email })
      .then(() => setEmailSent(true))
      .catch((err) => {
        errorLogger(true, `changePasswordAuth0: ${err}`);
      });
  };

  const openLoginModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    openModal(({ handleCloseModal }: HandleCloseModal) => (
      <LoginModal handleCloseModal={handleCloseModal} />
    ));
  };

  const canSubmit = !!email && emailValid;

  const loginLink = (
    <LinkWrapper className={styles.loginLink} to="/login">
      Log in
    </LinkWrapper>
  );

  const loginModalLink = (
    <button onClick={openLoginModal} className={styles.loginLink}>
      Log in
    </button>
  );

  const progressBar = (
    <div className={styles.progressBarWrapper}>
      <ProgressBarStepped totalCount={1} currentCount={1} />
    </div>
  );

  if (emailSent) {
    return (
      <div className={styles.formContent}>
        <h1 className={styles.title}>Email sent</h1>
        <p className={styles.helperText}>
          If you don&apos;t receive it within 10 minutes please reach out to customer support via
          email or twitter.
        </p>
        <div className={styles.progressBarWrapper}>
          <ProgressBarStepped totalCount={1} currentCount={1} />
        </div>
        <Icon name="detailedEmail" className={styles.emailSentIcon} />
      </div>
    );
  }

  return (
    <div className={styles.formContent}>
      <h1 className={styles.title}>Reset password</h1>
      <p className={styles.helperText}>
        Fill in your information and we&apos;ll send you a link to reset your password.
      </p>
      {isModal ? null : progressBar}
      <form onSubmit={onSubmit} className={styles.form}>
        <TextField
          label="Email address"
          name="email"
          value={email}
          valid={emailValid}
          error={emailError}
          placeholder="Enter the email that you signed up with"
          onChange={onEmailChange}
          onBlur={onEmailBlur}
          classNames={{ wrapper: styles.fieldWrapper }}
          giveFocus
        />
        <Button
          text="Reset"
          color="green"
          size="small"
          width="intrinsic"
          type="solid"
          disabled={!canSubmit}
          classNames={{ button: styles.button }}
        />
      </form>
      <p className={styles.login}>
        Remembered your password?
        {isModal ? loginModalLink : loginLink}
      </p>
    </div>
  );
};

export default ForgotPasswordForm;
