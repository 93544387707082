import statLineAdapter from '@/store/modules/constructed-slates/adapters/stat-line';
import { getFeatureFlagFromLocalStorage } from '@/store/modules/feature-flags/utils';
import matchAdapter from '@/store/modules/matches/adapters/match';
import overUnderLineSwapAdapter from '@/store/modules/pick-em/adapters/over-under-line-swap';
import soloGameAdapter from '@/store/modules/pick-em/adapters/solo-game';
import { adjustSlipType, getBoostType } from '@/store/modules/pick-em/reducer/helpers/index';

import { ampli, TokenAddedToEntryProperties, TokenAppliedToSelectionProperties } from '@/ampli';
import {
  SelectedOverUnder,
  SelectedSharedOverUnder,
} from '@/interfaces/constructed-interfaces/constructed-pick-em-over-under-appearance';
import { SelectedRival } from '@/interfaces/constructed-interfaces/constructed-pick-em-rival-appearance';
import {
  MatchUpdateResponse,
  SoloGameUpdateResponse,
  StatLineUpdateResponse,
} from '@/interfaces/live';
import {
  Boost,
  OtherPayout,
  OverUnderLineSwapResponse,
  PickEmEntries,
  PickEmEntrySlip,
  PickEmSlipType,
} from '@/interfaces/pick-em';
import { PowerUpInventoryUpdatedResponse, RedeemablePowerUp } from '@/interfaces/power-ups';
import { formatSelectedRivalLine, formatSOULine } from '@/utilities/amplitude/helpers';
import { LAST_SELECTED_LINE_ID, UD_PICK_EM } from '@/utilities/constants';
import errorLogger from '@/utilities/errors/logger';
import { arrayToObjectIdKeys } from '@/utilities/helpers';
import langHelper from '@/utilities/lang-helper';

import { UpdateSelectedArgs } from '../../actions';

export const updateLiveStatLine = (
  state: PickEmEntries,
  { data }: { data: StatLineUpdateResponse }
) => {
  const newStatLine = statLineAdapter(data.stat_line);

  const prevLiveStatLine = state.live.liveStatLines[data.stat_line.owner_id]?.data;

  if (prevLiveStatLine) {
    Object.keys(prevLiveStatLine).forEach((stat) => {
      const prev = parseFloat(prevLiveStatLine[stat]);
      const updated = parseFloat(newStatLine.data[stat]);

      // logs errors if the pusher event sends wrong data (ie. difference in stat is too high)
      if (updated - prev > 5) {
        errorLogger(true, `liveStatLine update error, previousValue ${prev}, newValue ${updated}`);
      }
    });
  }

  return {
    ...state,
    live: {
      ...state.live,
      liveStatLines: {
        ...state.live.liveStatLines,
        [data.stat_line.owner_id]: newStatLine,
      },
    },
  };
};

export const updateLiveGame = (state: PickEmEntries, { data }: { data: MatchUpdateResponse }) => {
  const newGame = matchAdapter(data.game);
  return {
    ...state,
    live: {
      ...state.live,
      liveGames: {
        ...state.live.liveGames,
        [data.game.id]: newGame,
      },
    },
  };
};

export const updateLiveSoloGame = (
  state: PickEmEntries,
  { data }: { data: SoloGameUpdateResponse }
) => {
  const newGame = soloGameAdapter(data.solo_game);
  return {
    ...state,
    live: {
      ...state.live,
      soloGames: {
        ...state.live.soloGames,
        [data.solo_game.id]: newGame,
      },
    },
  };
};

export const updateOverUnders = (
  state: PickEmEntries,
  {
    action,
    selection,
    overUnderLine,
    isPickEmFeatureEnabled,
    lobbyName,
    pickLocation,
    minInsuredPicks,
    maxUserPicks,
  }: UpdateSelectedArgs<SelectedOverUnder>
) => {
  // If Pick'em feature is not enabled, return right away.
  if (!isPickEmFeatureEnabled) {
    return {
      ...state,
      selected: {
        ...state.selected,
        error: "Unfortunately Pick'em is not available in your location",
      },
    };
  }

  let pickEmStateToReturn: PickEmEntries = null;

  const { selectedOverUnders, selectedRivals } = state.selected;
  const selectionCount = selectedOverUnders.length + selectedRivals.length;

  const { payoutOutcome } = state;

  if (!action) {
    // no action sent if clicked after line was removed by api
    const filteredSelections = state.selected.selectedOverUnders.filter(
      (sOU) => sOU.overUnderLineId !== selection.overUnderLineId
    );

    const boostType = getBoostType({
      selectedOverUnders: filteredSelections,
    });

    const slipType = adjustSlipType({
      boostType,
      currentSlipType: state.selected.slipType,
      selectionCount: [...filteredSelections, ...state.selected.selectedRivals].length,
    });

    pickEmStateToReturn = {
      ...state,
      selected: {
        ...state.selected,
        selectedOverUnders: filteredSelections,
        slipType,
        error: 'This player is no longer available.',
      },
    };
  }

  if (action === 'remove') {
    // filter out current selection
    const filteredSelections = state.selected.selectedOverUnders.filter(
      (sOU) => sOU.overUnderLineId !== selection.overUnderLineId
    );

    // double check that all selections are unique
    const uniqueSelections =
      filteredSelections &&
      filteredSelections.filter(
        (newSelectedOverUnder, ind, arr) =>
          arr.findIndex((sOU) => sOU.overUnderLineId === newSelectedOverUnder.overUnderLineId) ===
          ind
      );

    const boostType = getBoostType({
      selectedOverUnders: uniqueSelections,
    });

    const slipType = adjustSlipType({
      boostType,
      currentSlipType: state.selected.slipType,
      selectionCount: [...state.selected.selectedRivals, ...uniqueSelections].length,
    });

    const tooFewSelections =
      slipType === 'insurance' &&
      [...state.selected.selectedRivals, ...uniqueSelections].length < minInsuredPicks;

    ampli.removePickFromYourPicksScreen({
      pick_sport_name: selection.constructedOverUnderAppearance.sport.id,
      stat_name: selection.overUnderLineInfo.displayStat,
    });

    pickEmStateToReturn = {
      ...state,
      selected: {
        ...state.selected,
        selectedOverUnders: uniqueSelections,
        slipType,
        error: tooFewSelections ? `Make at least ${minInsuredPicks} picks to flex a payout.` : null,
      },
    };
  }

  if (action === 'add') {
    // selection exists when user is changing a selected line option
    const selectionExists = !!state.selected.selectedOverUnders?.find(
      (sOU) => sOU.overUnderLineId === selection.overUnderLineId
    );

    // check if appearance exists in slip
    const appearanceExists = state.selected.selectedOverUnders?.find(
      // not the exact selection, but the same appearance
      (sOU) =>
        sOU.constructedOverUnderAppearance.id === selection.constructedOverUnderAppearance.id &&
        sOU.overUnderLineId !== selection.overUnderLineId // ignore exact selection
    );

    // check if the selected line has a boost
    const selectionHasBoost =
      selection.overUnderLineId === overUnderLine.id && overUnderLine.overUnder.boost;

    let newSelections: SelectedOverUnder[];
    if (appearanceExists) {
      // if different overUnder, but same appearance, replace the selection
      newSelections = state.selected.selectedOverUnders.map((sOU: SelectedOverUnder) => {
        if (sOU.constructedOverUnderAppearance.id === selection.constructedOverUnderAppearance.id) {
          return selection;
        }
        return sOU;
      });
    } else if (selectionExists) {
      // if same overUnder, but different option, replace the selection
      newSelections = state.selected.selectedOverUnders.map((sOU) =>
        sOU.overUnderLineId === selection.overUnderLineId ? selection : sOU
      );
    } else {
      // return selections and new selection
      newSelections = [...state.selected.selectedOverUnders, selection];
      localStorage.setItem(LAST_SELECTED_LINE_ID, selection.overUnderLineId);
    }

    const uniqueSelections = newSelections.filter(
      (newSelectedOverUnder, ind, arr) =>
        arr.findIndex((sOU) => sOU.overUnderLineId === newSelectedOverUnder.overUnderLineId) === ind
    );

    const boostType = getBoostType({
      selectedOverUnders: uniqueSelections,
    });

    const slipType = adjustSlipType({
      boostType,
      currentSlipType: state.selected.slipType,
      selectionCount: [...state.selected.selectedRivals, ...newSelections].length,
    });

    const currPayoutStyle =
      slipType === 'insurance' ? payoutOutcome.insurance : payoutOutcome.standard;

    const maxSelectionsFromPayoutStyle: number = currPayoutStyle?.otherPayouts?.reduce(
      (acc: number, curr: OtherPayout) => Math.max(acc, curr.selectionCount),
      currPayoutStyle?.selectionCount
    );

    const maxSelections = Math.min(maxSelectionsFromPayoutStyle, maxUserPicks);

    if (!selectionExists && !appearanceExists && selectionCount >= maxSelections) {
      return {
        ...state,
        selected: {
          ...state.selected,
          slipType,
          error: `You've reached the max number of picks for this entry (${maxSelections}) remove a pick to add this one.`,
        },
      };
    }

    // check if boost exists in slip
    const boostExists = state.selected.selectedOverUnders?.find((sOU) => sOU.boost !== null);

    // check if appearance exists in a rival in slip
    const rivalAppearanceExists = state.selected.selectedRivals?.find((sR) =>
      sR.constructedPickEmRivalLine.appearanceOptions.find(
        (aO) => aO.id === selection.constructedOverUnderAppearance.id
      )
    );

    // user can click on two diff boosted lines if same appearance,
    // as long as no boosted line exists in slip
    const hasBoostAppearanceConflict =
      appearanceExists &&
      appearanceExists?.constructedOverUnderAppearance.id !==
        boostExists?.constructedOverUnderAppearance.id;

    const discountedOrBoosted = slipType === 'discount' ? 'discounted' : 'boosted';

    if (
      !selectionExists &&
      boostExists &&
      selectionHasBoost &&
      (!appearanceExists || hasBoostAppearanceConflict)
    ) {
      return {
        ...state,
        selected: {
          ...state.selected,
          slipType,
          error: `You already have a ${discountedOrBoosted} pick for ${langHelper.getPlayerFullName(
            boostExists?.constructedOverUnderAppearance.player
          )} in your entry. You'll need to remove it if you want to add this pick.`,
        },
      };
    }

    if (rivalAppearanceExists) {
      return {
        ...state,
        selected: {
          ...state.selected,
          slipType,
          error: `You already have a rival pick that includes ${langHelper.getPlayerFullName(
            selection.constructedOverUnderAppearance.player
          )} in your entry. You'll need to remove it if you want to add this pick.`,
        },
      };
    }

    const amplitudeFormattedSelection = formatSOULine({
      overUnderLine,
      lobbyName,
      pickLocation,
      selection,
    });
    ampli.pickemHigherLowerSelectionAdded(amplitudeFormattedSelection);

    pickEmStateToReturn = {
      ...state,
      selected: {
        ...state.selected,
        selectedOverUnders: uniqueSelections,
        slipType,
      },
    };
  }

  updatePickEmLocalStorage({
    selectedOverUnders: pickEmStateToReturn.selected.selectedOverUnders,
    selectedRivals: pickEmStateToReturn.selected.selectedRivals,
    slipType: pickEmStateToReturn.selected.slipType,
    error: null,
    loaded: false,
  });

  return pickEmStateToReturn;
};

export const updatePicksFromSlip = (
  state: PickEmEntries,
  {
    selectedRivals,
    selectedOverUnders,
  }: {
    selectedRivals: SelectedRival[];
    selectedOverUnders: SelectedSharedOverUnder[];
  }
) => {
  // Shared slip picks need their own reducer because slip selections are only saved on submit.
  // Errors are handled in shared-slip-incoming-modal & over-under-incoming-share-cell.
  const filteredSelectedOverUnders = selectedOverUnders;

  const boostType = getBoostType({
    selectedOverUnders: filteredSelectedOverUnders,
  });

  const filteredSelectedRivals = selectedRivals.filter(
    (sR) => sR.constructedPickEmRivalLine.status !== 'removed'
  );
  const selectionCount = filteredSelectedOverUnders.length + filteredSelectedRivals.length;

  const slipType = adjustSlipType({
    boostType,
    currentSlipType: state.selected.slipType,
    selectionCount,
  });

  updatePickEmLocalStorage({
    selectedOverUnders: filteredSelectedOverUnders,
    selectedRivals: filteredSelectedRivals,
    slipType,
    error: null,
    loaded: false,
  });

  return {
    ...state,
    selected: {
      ...state.selected,
      selectedRivals: filteredSelectedRivals,
      selectedOverUnders: filteredSelectedOverUnders,
      slipType,
    },
  };
};

export const updateRivals = (
  state: PickEmEntries,
  {
    action,
    selection,
    isPickEmFeatureEnabled,
    lobbyName,
    pickLocation,
    minInsuredPicks,
    maxUserPicks,
  }: UpdateSelectedArgs<SelectedRival>
) => {
  const { payoutOutcome } = state;
  let pickEmStateToReturn: PickEmEntries = null;

  if (!isPickEmFeatureEnabled) {
    return {
      ...state,
      selected: {
        ...state.selected,
        error: "Unfortunately Pick'em is not available in your location",
      },
    };
  }

  const selectionCount =
    state.selected.selectedOverUnders.length + state.selected.selectedRivals.length;

  if (!action) {
    const filteredRivals = state.selected.selectedRivals.filter((sR) => sR.id !== selection.id);
    const slipType: PickEmSlipType = adjustSlipType({
      boostType: null,
      currentSlipType: state.selected.slipType,
      selectionCount,
    });

    pickEmStateToReturn = {
      ...state,
      selected: {
        ...state.selected,
        slipType,
        selectedRivals: filteredRivals,
        error: 'This player is no longer available',
      },
    };
  }

  if (action === 'remove') {
    const filteredSelections = state.selected.selectedRivals.filter(
      (existingRival) => existingRival.id !== selection.id
    );

    const uniqueSelections = filteredSelections.filter(
      (newSelection, ind, arr) => arr.findIndex((s) => s.id === newSelection.id) === ind
    );

    const slipType: PickEmSlipType = adjustSlipType({
      boostType: null,
      currentSlipType: state.selected.slipType,
      selectionCount: [...state.selected.selectedOverUnders, ...uniqueSelections].length,
    });

    const tooFewSelections =
      slipType === 'insurance' &&
      [...state.selected.selectedOverUnders, ...uniqueSelections].length < minInsuredPicks;

    ampli.removePickFromYourPicksScreen({
      pick_sport_name: selection.constructedPickEmRivalAppearance.sport.id,
      stat_name: selection.constructedPickEmRivalAppearance.rivalOption.appearanceStat.displayStat,
    });

    pickEmStateToReturn = {
      ...state,
      selected: {
        ...state.selected,
        slipType,
        selectedRivals: uniqueSelections,
        error: tooFewSelections ? `Make at least ${minInsuredPicks} picks to flex a payout.` : null,
      },
    };
  }

  if (action === 'add') {
    // selection exists when user is changing the rival option
    const selectionExists = !!state.selected.selectedRivals?.find((sR) => sR.id === selection.id);

    let newSelections;
    if (selectionExists) {
      newSelections = state.selected.selectedRivals.map((sR) =>
        sR.id === selection.id ? selection : sR
      );
    } else {
      newSelections = [...state.selected.selectedRivals, selection];
    }
    const slipType: PickEmSlipType = adjustSlipType({
      boostType: null,
      currentSlipType: state.selected.slipType,
      selectionCount: [...state.selected.selectedOverUnders, ...newSelections].length,
    });

    const currPayoutStyle =
      slipType === 'insurance' ? payoutOutcome.insurance : payoutOutcome.standard;
    const maxSelectionsFromPayoutStyle: number = currPayoutStyle?.otherPayouts?.reduce(
      (acc: number, curr: OtherPayout) => Math.max(acc, curr.selectionCount),
      currPayoutStyle?.selectionCount
    );

    const maxSelections = Math.min(maxSelectionsFromPayoutStyle, maxUserPicks);

    if (!selectionExists && selectionCount >= maxSelections) {
      return {
        ...state,
        selected: {
          ...state.selected,
          slipType,
          error: `You've reached the max number of picks for this entry (${maxSelections}) remove a pick to add this one.`,
        },
      };
    }

    const appearanceExists =
      state.selected.selectedOverUnders?.find(
        // not the exact selection, but the same appearance
        (
          sOU // check first for a over under that matches the selection
        ) => sOU.constructedOverUnderAppearance.id === selection.constructedPickEmRivalAppearance.id
      ) ||
      state.selected.selectedOverUnders?.find(
        (
          sOU // then search for an over under that matches either side of the  rival
        ) =>
          sOU.constructedOverUnderAppearance.id ===
            selection.constructedPickEmRivalLine.appearanceOptions[0].id ||
          sOU.constructedOverUnderAppearance.id ===
            selection.constructedPickEmRivalLine.appearanceOptions[1].id
      );

    // the appearanceOption type on this page will always be ConstructedPickEmRivalLineAppearance
    const rivalAppearanceExists = state.selected.selectedRivals?.find(
      // for some reason typescript can't work this out itself https://github.com/microsoft/TypeScript/issues/33591#issuecomment-786443978
      (sR) =>
        sR.constructedPickEmRivalLine.appearanceOptions.find(
          (aO) =>
            (aO.id === selection.constructedPickEmRivalLine.appearanceOptions[0].id || // check first appearance option
              aO.id === selection.constructedPickEmRivalLine.appearanceOptions[1].id) && // check second appearance option
            sR.id !== selection.id // ignore exact selection
        )
    );

    if (appearanceExists) {
      const player =
        selection.constructedPickEmRivalAppearance.id ===
        appearanceExists.constructedOverUnderAppearance.id // if appearance exists is same as selection
          ? selection.constructedPickEmRivalAppearance.player // then it's the right player
          : selection.constructedPickEmRivalLine.appearanceOptions.find(
              // otherwise
              (aO) => aO.id === appearanceExists.constructedOverUnderAppearance.id // grab the appearance that matches the appearance exists
            ).player;

      return {
        ...state,
        selected: {
          ...state.selected,
          slipType,
          error: `You already have a higher/lower for ${langHelper.getPlayerFullName(
            player
          )} in your entry. You'll need to remove it if you want to add this pick`,
        },
      };
    }

    if (rivalAppearanceExists) {
      const player = rivalAppearanceExists.constructedPickEmRivalLine.appearanceOptions.find(
        (aO) => aO.id === selection.constructedPickEmRivalAppearance.id // if the existing rival appearance exists, see if it include the selection
      )
        ? selection.constructedPickEmRivalAppearance.player // return the selection if it's in the existing rival appearance
        : selection.constructedPickEmRivalLine.appearanceOptions.find(
            // if not it'll be the opponent in the selection
            (aO) => aO.id !== selection.constructedPickEmRivalAppearance.id // which will be the appearance option that doesn't match the selection
          )?.player;

      return {
        ...state,
        selected: {
          ...state.selected,
          slipType,
          error: `You already have a rival pick that includes ${langHelper.getPlayerFullName(
            player
          )} in your entry. You'll need to remove it if you want to add this pick`,
        },
      };
    }

    // this should just make sure the selections are unique, it may not be necessary
    const uniqueSelections = newSelections.filter(
      (newSelection, ind, arr) => arr.findIndex((s) => s.id === newSelection.id) === ind
    );

    const amplitudeFormattedSelection = formatSelectedRivalLine({
      lobbyName,
      pickLocation,
      selection,
    });
    ampli.pickemRivalsSelectionAdded(amplitudeFormattedSelection);
    localStorage.setItem(LAST_SELECTED_LINE_ID, selection.id);

    pickEmStateToReturn = {
      ...state,
      selected: {
        ...state.selected,
        slipType,
        selectedRivals: uniqueSelections,
      },
    };
  }

  updatePickEmLocalStorage({
    selectedOverUnders: pickEmStateToReturn.selected.selectedOverUnders,
    selectedRivals: pickEmStateToReturn.selected.selectedRivals,
    slipType: pickEmStateToReturn.selected.slipType,
    error: null,
    loaded: false,
  });

  return pickEmStateToReturn;
};

// We keep this callback in the pick-em-entries reducer because
// this event that may cause the selected line and option
// status to change and we want to be able to access that information
// in the review section without getting all the overUnderLines from the
// store. eg. if a selected line is suspended, we need to update the
// button (enable/disable) in the submission section.
export const updateSelectedOverUnderLineStatus = (
  state: PickEmEntries,
  {
    overUnderLineIds,
    statusType,
  }: {
    overUnderLineIds: string[];
    statusType: 'suspended' | 'unsuspended';
  }
): PickEmEntries => {
  if (!state.selected.selectedOverUnders || !state.selected.selectedOverUnders.length) return state;

  // Check if we need to bother updating the selectedOverUnders. If the selectedOverUnders
  // list has no overUnderLineIds that match the overUnderLineIds in the event, then we don't
  // need to update the selectedOverUnders.
  const needsUpdating = state.selected.selectedOverUnders.some((selectedOverUnder) =>
    overUnderLineIds.includes(selectedOverUnder.overUnderLineId)
  );

  if (!needsUpdating) return state;

  const newSelectedOverUnders: SelectedOverUnder[] = state.selected.selectedOverUnders.map(
    (sOU) => {
      if (overUnderLineIds.includes(sOU.overUnderLineId)) {
        return {
          ...sOU,
          overUnderLineInfo: {
            ...sOU.overUnderLineInfo,
            status: statusType === 'suspended' ? 'suspended' : 'active',
          },
        };
      }
      return sOU;
    }
  );
  return {
    ...state,
    selected: {
      ...state.selected,
      selectedOverUnders: newSelectedOverUnders,
    },
  };
};

// We keep this callback in the pick-em-entries reducer because
// this event that may cause the selected line and option
// ids to change. If a selected line is swapped, we need to update the
// selectedOverUnders in the store to keep the review section in sync.
export const swapSelectedOverUnderLine = (
  state: PickEmEntries,
  {
    data,
  }: {
    data: OverUnderLineSwapResponse[];
  }
): PickEmEntries => {
  const adaptedOverUnderLineSwapArray = data.map(overUnderLineSwapAdapter);
  const oldLineIds = adaptedOverUnderLineSwapArray.map((oULS) => oULS.oldId);
  const overUnderLineSwapByOldOId = arrayToObjectIdKeys(adaptedOverUnderLineSwapArray, 'oldId');

  const selectedOverUnderLineIds = state.selected.selectedOverUnders?.map(
    (selectedOverUnder) => selectedOverUnder.overUnderLineId
  );

  if (!selectedOverUnderLineIds) return state;

  // We want to updated the selectedOverUnders only if the line swap event has come through
  // for one of the selected line ids.
  const needToUpdateSelectedOverUnders = selectedOverUnderLineIds.some((overUnderLineId) =>
    oldLineIds.includes(overUnderLineId)
  );

  if (needToUpdateSelectedOverUnders) {
    const { selectedOverUnders } = state.selected;

    const newSelectedOverUnders: SelectedOverUnder[] = selectedOverUnders.map(
      (selectedOverUnder) => {
        if (oldLineIds.includes(selectedOverUnder.overUnderLineId)) {
          const replacementOverUnderLine =
            overUnderLineSwapByOldOId[selectedOverUnder.overUnderLineId];
          const { newId } = replacementOverUnderLine;
          const newOption = replacementOverUnderLine.options.find(
            (option) => option.choice === selectedOverUnder.option.choice
          );

          return {
            ...selectedOverUnder,
            overUnderLineId: newId,
            option: newOption,
            constructedOverUnderAppearance: {
              ...selectedOverUnder.constructedOverUnderAppearance,
            },
            overUnderLineInfo: {
              ...selectedOverUnder.overUnderLineInfo,
              status: 'active',
            },
          };
        }

        return selectedOverUnder;
      }
    );

    updatePickEmLocalStorage({ selectedOverUnders: newSelectedOverUnders });

    return {
      ...state,
      prevPayoutOutcome: state.payoutOutcome,
      selected: {
        ...state.selected,
        selectedOverUnders: newSelectedOverUnders,
      },
    };
  }

  return state;
};

export const clearEntrySlip = (state: PickEmEntries): PickEmEntries => {
  updatePickEmLocalStorage({
    selectedOverUnders: null,
    selectedRivals: null,
    slipType: null,
    error: null,
    loaded: false,
  });

  return {
    ...state,
    selected: {
      ...state.selected,
      powerUp: null,
      selectedRivals: [],
      selectedOverUnders: [],
      slipType: 'default', // always reset slip
    },
  };
};

export const removeErr = (state: PickEmEntries): PickEmEntries => ({
  ...state,
  selected: {
    ...state.selected,
    error: null,
  },
});

export const toggleInsurance = (
  state: PickEmEntries,
  {
    slipType,
    minInsuredPicks,
  }: {
    slipType: PickEmSlipType;
    minInsuredPicks: number;
  }
): PickEmEntries => {
  const isWebPick8Enabled = getFeatureFlagFromLocalStorage('webPick8');

  // if pick 8 enabled don't force default slipType when boost present
  if (
    !isWebPick8Enabled &&
    ['payoutBooster', 'specialLine', 'sweepstakes', 'discount'].includes(state.selected.slipType)
  ) {
    const label: string = langHelper.formatPickemBoostLabel(
      state.selected.slipType as Boost['boostType']
    );
    return {
      ...state,
      selected: {
        ...state.selected,
        error: `You have a ${label}${
          label === 'boost' || label === 'discount' ? 'ed' : ''
        } pick in your entry. You'll need to remove it if you want to flex this submission.`,
      },
    };
  }

  const tooFewSelections =
    slipType === 'insurance' &&
    [...state.selected.selectedOverUnders, ...state.selected.selectedRivals].length <
      minInsuredPicks;

  updatePickEmLocalStorage({
    selectedOverUnders: state.selected.selectedOverUnders,
    selectedRivals: state.selected.selectedRivals,
    slipType,
    error: null,
    loaded: false,
  });

  return {
    ...state,
    selected: {
      ...state.selected,
      slipType,
      error: tooFewSelections ? `Make at least ${minInsuredPicks} picks to flex a payout.` : null,
    },
  };
};

const updatePickEmLocalStorage = ({
  selectedOverUnders,
  selectedRivals,
  slipType,
  error,
  loaded,
  entrySlipLimits,
  powerUp,
}: Partial<PickEmEntrySlip>) => {
  const localStoragePickEm = JSON.parse(localStorage.getItem(UD_PICK_EM));

  localStorage.setItem(
    UD_PICK_EM,
    JSON.stringify({
      ...localStoragePickEm,
      selectedOverUnders:
        selectedOverUnders !== undefined
          ? selectedOverUnders
          : localStoragePickEm?.selectedOverUnders,
      selectedRivals:
        selectedRivals !== undefined ? selectedRivals : localStoragePickEm?.selectedRivals,
      slipType: slipType !== undefined ? slipType : localStoragePickEm?.slipType,
      error: error !== undefined ? error : localStoragePickEm?.error,
      loaded: loaded !== undefined ? loaded : localStoragePickEm?.loaded,
      entrySlipLimits:
        entrySlipLimits !== undefined ? entrySlipLimits : localStoragePickEm?.entrySlipLimits,
      powerUp: powerUp !== undefined ? powerUp : localStoragePickEm?.powerUp,
    })
  );
};

export const setPowerUpOnEntry = (
  state: PickEmEntries,
  {
    action,
    location,
    powerUp,
    reason,
  }: {
    action: 'add' | 'apiRemove' | 'remove';
    location: TokenAddedToEntryProperties['location'];
    powerUp: RedeemablePowerUp;
    reason?: PowerUpInventoryUpdatedResponse['reason'];
  }
): PickEmEntries => {
  const updatedOverUnders = state.selected.selectedOverUnders.map((sOU) => {
    const removeCurrPowerUp =
      (action === 'remove' && sOU.powerUpId === powerUp.id) || action === 'apiRemove';
    const replaceCurrPowerUp = action === 'add' && sOU.powerUpId !== powerUp.id;

    if (removeCurrPowerUp || replaceCurrPowerUp) {
      return {
        ...sOU,
        powerUpId: null,
      };
    }
    return sOU;
  });

  if (location) {
    if (action === 'add') {
      ampli.tokenAddedToEntry({
        location,
        token_type: powerUp.type,
      });
    }

    if (action === 'remove') {
      ampli.tokenRemovedFromEntry({
        location,
        token_type: powerUp.type,
      });
    }
  }

  updatePickEmLocalStorage({
    ...state.selected,
    selectedOverUnders: updatedOverUnders,
    powerUp: action === 'add' ? powerUp : null,
  });

  return {
    ...state,
    selected: {
      ...state.selected,
      selectedOverUnders: updatedOverUnders,
      powerUp: action === 'add' ? powerUp : null,
      error:
        action === 'apiRemove' && reason !== 'submission' // triggered by pusher event
          ? `The token has expired and been removed from your entry.`
          : state.selected.error,
    },
  };
};

export const setPowerUpOnSelectedOverUnder = (
  state: PickEmEntries,
  {
    action,
    location,
    overUnderLineId,
  }: {
    action: 'add' | 'remove';
    location: TokenAppliedToSelectionProperties['location'];
    overUnderLineId: string;
  }
): PickEmEntries => {
  const updatedOverUnders = state.selected.selectedOverUnders.map((sOU) => {
    return {
      ...sOU,
      powerUpId:
        action === 'add' && sOU.overUnderLineId === overUnderLineId
          ? state.selected.powerUp?.id
          : null,
    };
  });

  if (action === 'add') {
    ampli.tokenAppliedToSelection({
      location,
      token_type: state.selected.powerUp.type,
    });
  }

  if (action === 'remove') {
    ampli.tokenRemovedFromSelection({
      location,
      token_type: state.selected.powerUp.type,
    });
  }

  updatePickEmLocalStorage({
    ...state.selected,
    selectedOverUnders: updatedOverUnders,
  });

  return {
    ...state,
    selected: {
      ...state.selected,
      selectedOverUnders: updatedOverUnders,
    },
  };
};
