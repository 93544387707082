import React from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';

import { RootState } from '@/store';

import { AllStateConfigurations } from '@/interfaces/state-configuration';
import { User } from '@/interfaces/user';

import { formatStateSpecificRGText, ResponsibleGamingTextType } from './helpers';

import styles from './styles.scss';

export interface ResponsibleGamingCardProps {
  authenticated: boolean;
  iconPosition: 'right' | 'left';
  stateConfigurations: AllStateConfigurations;
  textType: ResponsibleGamingTextType;
  user: User;
  variant: 'small' | 'large';
}

const ResponsibleGamingCard = (props: ResponsibleGamingCardProps) => {
  const { authenticated, iconPosition, stateConfigurations, textType, user, variant } = props;

  const isStateConfigEmpty = Object.keys(stateConfigurations).length > 0;

  if (!isStateConfigEmpty) {
    return <div className={clsx(variant === 'small' ? styles.small : styles.large)} />;
  }

  const stateRGText: React.ReactNode = formatStateSpecificRGText({
    authenticated,
    textType,
    stateConfigurations,
    user,
  });

  if (!stateRGText) {
    return null;
  }

  return (
    <div
      className={clsx(
        styles.responsibleGamingCard,
        iconPosition === 'right' ? styles.rightIcon : styles.leftIcon
      )}
    >
      <div>
        <p className={styles.responsibleGamingHeading}>Underdog Responsible Gaming</p>
        <h3 className={styles.concernedHeading}>Concerned with your play?</h3>
        <div className={styles.responsibleGamingText}>{stateRGText}</div>
      </div>
    </div>
  );
};

export default connect((state: RootState) => ({
  stateConfigurations: state.stateConfigurations,
  user: state.user,
}))(ResponsibleGamingCard);
