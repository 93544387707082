/* eslint-disable camelcase */

import { Player, PlayerResponse } from '@/interfaces/constructed-interfaces/constructed-slates';

const playerAdapter = (player: PlayerResponse): Player => {
  const {
    id,
    bats,
    birthdate,
    country,
    first_name,
    image_url,
    last_name,
    number,
    position_id,
    sport_id,
    team_id,
    throws,
  } = player;

  return {
    id,
    bats,
    birthdate,
    country,
    firstName: first_name,
    imageUrl: image_url,
    lastName: last_name,
    number,
    positionId: position_id,
    sportId: sport_id,
    teamId: team_id,
    throws,
  };
};

export default playerAdapter;
