import {
  AppearanceLine,
  ConstructedPickEmOverUnderLineAppearance,
  ConstructedPickEmOverUnderLineAppearances,
} from '@/interfaces/constructed-interfaces/constructed-pick-em-over-under-appearance';
import { Appearance } from '@/interfaces/constructed-interfaces/constructed-slates';
import { LineupStatuses, Sports, Teams } from '@/interfaces/drafting-config';
import { ByeWeek, Matches } from '@/interfaces/matches';
import { FavoritePlayers, OverUnderLine, SoloGames } from '@/interfaces/pick-em';
import { SportId } from '@/interfaces/types';
import { arrayToObjectIdKeys } from '@/utilities/helpers';

export const pickEmOverUnderAppearanceConstructor = ({
  appearance,
  games,
  lineupStatuses,
  players,
  overUnderLines: allOverUnderLines,
  soloGames,
  sports,
  teams,
}: {
  appearance: Appearance;
  games: Matches;
  lineupStatuses: LineupStatuses;
  players: FavoritePlayers;
  overUnderLines: OverUnderLine[];
  soloGames: SoloGames;
  sports: Sports;
  teams: Teams;
}): ConstructedPickEmOverUnderLineAppearance => {
  const overUnderLines = allOverUnderLines.filter(
    (oUL) => oUL.overUnder.appearanceStat.appearanceId === appearance.id
  );

  if (overUnderLines.length === 0) {
    return null;
  }
  const team = teams[appearance.teamId];
  const player = players[appearance.playerId];
  const sport = sports[player?.sportId];
  const lineupStatus = appearance.lineupStatusId ? lineupStatuses[appearance.lineupStatusId] : null;
  const match = appearance.matchType === 'Game' ? games[appearance?.matchId] : null;
  const soloGame = appearance.matchType === 'SoloGame' ? soloGames[appearance?.matchId] : null;
  const byeWeek = match && match.byeWeeks ? match.byeWeeks[appearance.teamId] : (null as ByeWeek);

  const sortedOverUnderLines = overUnderLines.sort((a, b) => a.rank - b.rank);
  const scoringTypeId = overUnderLines.find((line) => line?.overUnder?.scoringTypeId)?.overUnder
    ?.scoringTypeId;

  const sortedAppearanceLines: AppearanceLine[] = [];
  const lineIdsPriority: AppearanceLine[] = [];
  const lineIdsRegular: AppearanceLine[] = [];
  const searchKeywords: string[] = [];

  sortedOverUnderLines.forEach((line) => {
    if (!line.splitLine) {
      lineIdsPriority.push({ id: line.id, splitLine: false, option: null });
    } else if (line.overUnder.optionPriority === 'lower') {
      lineIdsPriority.push({
        id: line.id,
        splitLine: true,
        option: 'lower',
      });
      lineIdsRegular.push({
        id: line.id,
        splitLine: true,
        option: 'higher',
      });
    } else {
      lineIdsPriority.push({
        id: line.id,
        splitLine: true,
        option: 'higher',
      });
      lineIdsRegular.push({
        id: line.id,
        splitLine: true,
        option: 'lower',
      });
    }

    searchKeywords.push(line.overUnder.appearanceStat.displayStat.toLowerCase());
  });
  sortedAppearanceLines.push(...lineIdsPriority);
  sortedAppearanceLines.push(...lineIdsRegular);

  const hasLiveLines = sortedOverUnderLines?.some((line) => line?.liveEvent);
  const boost = sortedOverUnderLines?.find((line) => line?.overUnder.boost)?.overUnder?.boost;
  const { expiresAt } = sortedOverUnderLines[0];

  const isActive = !sortedOverUnderLines.every(
    (line) => line.status === 'removed' || line.status === 'closed'
  );

  return {
    id: appearance.id,
    byeWeek,
    latestNewsItemUpdatedAt: appearance.latestNewsItemUpdatedAt,
    lineupStatus,
    match,
    matchType: appearance.matchType,
    boost,
    isActive,
    expiresAt,
    hasLiveLines,
    scoringTypeId,
    appearanceLines: sortedAppearanceLines,
    player,
    projection: appearance.projection,
    positionId: appearance.positionId,
    rank: sortedOverUnderLines[0]?.rank,
    score: appearance.score,
    soloGame,
    sport,
    team,
    searchKeywords: searchKeywords.join(','),
  };
};

export const pickEmOverUnderAppearancesConstructor = ({
  appearances,
  games,
  isAdmin,
  lineupStatuses,
  players,
  overUnderLines,
  soloGames,
  sports,
  teams,
  visibleSportIds,
}: {
  appearances: Appearance[];
  games: Matches;
  isAdmin: boolean;
  lineupStatuses: LineupStatuses;
  players: FavoritePlayers;
  overUnderLines: OverUnderLine[];
  soloGames: SoloGames;
  sports: Sports;
  teams: Teams;
  visibleSportIds: SportId[];
}): ConstructedPickEmOverUnderLineAppearances => {
  const appearancesArray = appearances
    .map((appearance) =>
      pickEmOverUnderAppearanceConstructor({
        appearance,
        games,
        lineupStatuses,
        players,
        overUnderLines,
        soloGames,
        sports,
        teams,
      })
    )
    .filter((cA) => {
      if (!cA) return false;
      if (isAdmin) return true;
      // If the state config has been successfully loaded, filter appearances
      // by visibleSportIds.
      if (visibleSportIds && visibleSportIds.length > 0) {
        return (
          visibleSportIds.includes(cA.match?.sportId) ||
          visibleSportIds.includes(cA.soloGame?.sportId)
        );
      }
      // If there was an error fetching the state config,
      // visibleSportIds would be null, so we just return all
      // appearances so that the lobby doesn't stay frozen in
      // the loading state.
      return true;
    })
    .sort((a, b) => a.rank - b.rank);

  return arrayToObjectIdKeys(appearancesArray);
};
