import dayjs from 'dayjs';

import {
  ConstructedPickEmOverUnderLineAppearance,
  ConstructedPickEmOverUnderLineAppearances,
} from '@/interfaces/constructed-interfaces/constructed-pick-em-over-under-appearance';
import { Match } from '@/interfaces/matches';
import { PickEmEventTiming } from '@/interfaces/pick-em';
import { SportId } from '@/interfaces/types';
import { normalizeText } from '@/utilities/helpers';

export interface FilteredGame {
  id: number;
  title: string;
  date: string;
  matchProgress: string | null;
  homeTeamScore: number;
  awayTeamScore: number;
  constructedAppearances: ConstructedPickEmOverUnderLineAppearance[];
  rank: number; // just used for ampli
  status: Match['status'];
}
export const getFilteredAppearances = ({
  constructedAppearances,
  sportId = 'home',
  pickTiming = 'all',
  searchTerm,
}: {
  constructedAppearances: ConstructedPickEmOverUnderLineAppearances;
  sportId: SportId;
  pickTiming?: PickEmEventTiming;
  searchTerm?: string;
}) => {
  if (!constructedAppearances) return [];

  let filteredAppearances = Object.values(constructedAppearances);

  if (sportId !== 'home') {
    filteredAppearances = filteredAppearances.filter(
      (cA) => cA.player.sportId.toLowerCase() === sportId.toLowerCase()
    );
  }

  if (pickTiming !== 'all') {
    filteredAppearances = filteredAppearances.filter(
      (constructedAppearance) =>
        (pickTiming === 'in-game' && constructedAppearance.hasLiveLines === true) ||
        (pickTiming === 'pre-game' && constructedAppearance.hasLiveLines === false)
    );
  }

  if (searchTerm) {
    filteredAppearances = getFilteredAppearancesBySearchTerm({
      constructedAppearances: filteredAppearances,
      searchTerm,
    });
  }

  return filteredAppearances;
};

const getFilteredAppearancesBySearchTerm = ({
  constructedAppearances,
  searchTerm,
}: {
  constructedAppearances: ConstructedPickEmOverUnderLineAppearance[];
  searchTerm: string;
}): ConstructedPickEmOverUnderLineAppearance[] => {
  if (!searchTerm) {
    return constructedAppearances;
  }

  const filteredAppearances = constructedAppearances.map((cA) => {
    const { player, team } = cA;

    const playerName = `${player.firstName} ${player.lastName}`;
    const teamName = team ? `${team.name} ${team.abbr}` : '';

    const isTermInPlayerName = doesTextContainSearchTerm(playerName, searchTerm);
    const isTermInTeamName = doesTextContainSearchTerm(teamName, searchTerm);

    const matchingDisplayStats = cA.searchKeywords?.includes(searchTerm.toLowerCase());

    if (isTermInPlayerName || isTermInTeamName || matchingDisplayStats) {
      return cA;
    }
    return null;
  });

  return filteredAppearances.filter(
    (appearance) => appearance !== null
  ) as ConstructedPickEmOverUnderLineAppearance[];
};

/**
 * @param text the text being searched (haystack)
 * @param normalizedTerm the text we're searching for (needle)
 * @returns boolean
 */
export const doesTextContainSearchTerm = (text: string, normalizedTerm: string) =>
  normalizeText(text).includes(normalizedTerm);

export const sortGamesByDate = (arr: FilteredGame[]) =>
  arr?.sort((prev, curr) => {
    // sort by date ASC (sort by ID ASC, if dates match)
    if (dayjs(curr.date).isBefore(prev.date)) return 1;
    if (dayjs(curr.date).isAfter(prev.date)) return -1;
    if (dayjs(curr.date).isSame(prev.date)) {
      // eslint-disable-next-line max-len
      if (curr.constructedAppearances[0].rank < prev.constructedAppearances[0].rank) return 1;
      // eslint-disable-next-line max-len
      if (curr.constructedAppearances[0].rank > prev.constructedAppearances[0].rank) return -1;
      return curr.id < prev.id ? 1 : -1;
    }
    return 0;
  });

export const groupAppearancesByGame = (arr: ConstructedPickEmOverUnderLineAppearance[]) => {
  return arr?.reduce(
    (acc: FilteredGame[], curr: ConstructedPickEmOverUnderLineAppearance): FilteredGame[] => {
      const index = acc.findIndex((item: any) => item.title === curr?.match?.title);
      if (index >= 0) {
        acc[index].constructedAppearances.push(curr);
      } else {
        acc.push({
          id: curr.match?.id,
          title: curr.match?.title,
          date: curr.match?.scheduledAt,
          matchProgress: curr.match?.matchProgress,
          homeTeamScore: curr.match?.homeTeamScore,
          awayTeamScore: curr.match?.awayTeamScore,
          constructedAppearances: [curr],
          rank: curr.rank,
          status: curr.match?.status,
        });
      }
      return acc;
    },
    []
  );
};
