import React from 'react';
import clsx from 'clsx';

import styles from './styles.scss';

const InGameInfo = () => (
  <>
    <h2 className={styles.infoHeader}>What are in-game projections?</h2>
    <p className={styles.text}>
      In-game projections allow for picks to be made after a player&apos;s game has started. The
      projection is for the entirety of the game and picks will be graded based on their total stats
      at the end of the game.
    </p>
    <p className={styles.text}>
      For example, if LeBron has an in-game projection of 27.5 points, and his current stat is 7,
      LeBron would need to score at least 21 more points to finish with 28 or more points, for
      selections of &quot;higher&quot; to win. He would need to score fewer than 21 more points (for
      a total less than 27.5 points) for &quot;lower&quot; to win.&nbsp;
      <a
        href="https://underdogfantasy.com/rules/pick-em#in-game%20projections"
        target="_blank"
        rel="noopener noreferrer"
        className={styles.link}
      >
        Learn more.
      </a>
    </p>
    <h2 className={styles.infoHeader}>Stat projections</h2>
    <p className={styles.text}>
      Live stats are frequently updated but may be delayed. The &lsquo;Current Stats&rsquo;
      displayed are provided by a 3rd party source and are unofficial. All grading of in-game picks
      will be determined by their final stat line at the end of the contest.
    </p>
    <h3 className={styles.headerWithIcon}>
      <div className={clsx(styles.inGameProjectionStatIcon, styles.suspended)} />
      <p className={styles.infoHeader}>Updating</p>
    </h3>
    <p className={styles.text}>
      In-game projections are subject to frequent updates as a result of a player&apos;s live
      progress.
    </p>
    <p className={styles.text}>
      While updating, a pick is invalid. You may either wait to see the new projection or remove it
      from your entry.
    </p>
    <h3 className={styles.headerWithIcon}>
      <div className={clsx(styles.inGameProjectionStatIcon)} />
      <p className={styles.infoHeader}>Live</p>
    </h3>
    <p className={styles.text}>
      When an in-game projection is live, you&apos;ll see the player&apos;s current stat and a blue
      indicator.
    </p>
  </>
);

export default InGameInfo;
