/* eslint-disable camelcase */

import { ByeWeek, ByeWeeks, ByeWeeksResponse, Match, MatchResponse } from '@/interfaces/matches';
import { arrayToObjectIdKeys } from '@/utilities/helpers';

const byeWeeksAdapter = (byeWeeks: ByeWeeksResponse[]): ByeWeeks => {
  if (!byeWeeks || byeWeeks.length === 0) return null;
  const adaptedByeWeeksArr: ByeWeek[] = byeWeeks.map((byeWeek) => {
    const { id, team_id, week, year } = byeWeek;

    return {
      id,
      teamId: team_id,
      week,
      year,
    };
  });

  return arrayToObjectIdKeys(adaptedByeWeeksArr, 'teamId');
};

const matchAdapter = (match: MatchResponse): Match => {
  const {
    id,
    away_team_id,
    away_team_score,
    bye_weeks,
    description,
    end_at,
    home_team_id,
    home_team_score,
    manually_created,
    match_progress,
    period,
    scheduled_at,
    season_type,
    sport_id,
    status,
    title,
    type,
    year,
  } = match;

  const adaptedByeWeeks = bye_weeks && byeWeeksAdapter(bye_weeks);

  return {
    id,
    awayTeamId: away_team_id,
    awayTeamScore: away_team_score,
    byeWeeks: adaptedByeWeeks,
    description,
    endAt: end_at,
    homeTeamId: home_team_id,
    homeTeamScore: home_team_score,
    manuallyCreated: manually_created,
    matchProgress: match_progress,
    period,
    scheduledAt: scheduled_at,
    seasonType: season_type,
    sportId: sport_id,
    status,
    title,
    type,
    year,
  };
};

export default matchAdapter;
