import Cookies from 'js-cookie';

import { getFeatureFlagFromLocalStorage } from '@/store/modules/feature-flags/utils';

import { ErrorContext } from '@/interfaces/error';
import { UD_LAT, UD_LONG } from '@/utilities/constants';
import errorLogger from '@/utilities/errors/logger';
// eslint-disable-next-line import/no-cycle
import geoService from '@/utilities/location/geocomply';

import { provincesArray } from './constants';

/**
 * Check for lat and long in session storage, if we get them, then return them.
 *
 * If there is no lat/long in session storage then check if the browser has already
 * given us permission to get them. If we have existing permission, then set the
 * lat and long in sessionStorage and return the lat/long.
 */

export const getLocationHeaders = ({ required }: { required?: boolean } = {}): {
  lat: string;
  long: string;
} => {
  let lat = sessionStorage.getItem(UD_LAT);
  let long = sessionStorage.getItem(UD_LONG);

  // this is to check if there is a mobile location cookie set
  if (Cookies.get('ud_latitude') && Cookies.get('ud_longitude')) {
    lat = Cookies.get('ud_latitude');
    long = Cookies.get('ud_longitude');
  }
  if ((!lat || !long) && required) {
    try {
      navigator.permissions.query({ name: 'geolocation' }).then((result) => {
        if (result.state === 'granted') {
          navigator.geolocation.getCurrentPosition((location) => {
            lat = location.coords.latitude.toString();
            long = location.coords.longitude.toString();
            sessionStorage.setItem(UD_LAT, lat);
            sessionStorage.setItem(UD_LONG, long);
          });
        }
        // eslint-disable-next-line no-param-reassign
        result.onchange = () => {
          // if the user removes their location permissions, then clear the sessionStorage
          if (result.state !== 'granted') {
            sessionStorage.removeItem(UD_LAT);
            sessionStorage.removeItem(UD_LONG);
          }
        };
      });
    } catch (e) {
      // if navigator permissions aren't a thing, like in safari, then just get the location straight up
      navigator.geolocation.getCurrentPosition((location) => {
        lat = location.coords.latitude.toString();
        long = location.coords.longitude.toString();
        sessionStorage.setItem(UD_LAT, lat);
        sessionStorage.setItem(UD_LONG, long);
      });
    }
  }
  return { lat, long };
};

export const logoutLocation = () => {
  geoService.endUserSession();
};

/**
 * This just checks if the user has granted permission and returns true or false
 */
export const checkLocationPermissionsGranted = async () =>
  new Promise((resolve) => {
    try {
      navigator.permissions.query({ name: 'geolocation' }).then((result) => {
        if (result.state === 'granted') {
          resolve(true);
        }
        resolve(false);
      });
    } catch (e) {
      resolve(false);
    }
  });

/**
 * Ask for the user's location and store it in sessionStorage if successful
 */
export const askForLocation = ({
  onAsk,
  onSuccess,
  onFailure,
  errorContext,
  userId,
  triggerGeoComply,
}: {
  onAsk?: () => void;
  onSuccess?: () => void;
  onFailure?: (err?: any) => void;
  errorContext?: ErrorContext;
  userId?: string;
  triggerGeoComply?: boolean;
} = {}): void => {
  const isGeoComplyFeatureEnabled = getFeatureFlagFromLocalStorage('geoComply');

  if (onAsk) onAsk();
  if (isGeoComplyFeatureEnabled && triggerGeoComply) {
    if (geoService.initialized) {
      geoService
        .getToken()
        .then(() => {
          if (onSuccess) onSuccess();
        })
        .catch((e) => {
          errorLogger(true, `Error on askForLocation - ${JSON.stringify(errorContext)}`, {
            value: JSON.stringify(e),
          });
        });
    } else {
      geoService
        .init({ userId })
        .then(() => {
          geoService.getToken().then(() => {
            if (onSuccess) onSuccess();
          });
        })
        .catch((e) => {
          errorLogger(true, `Error on askForLocation - ${JSON.stringify(errorContext)}`, {
            value: JSON.stringify(e),
          });
        });
    }
  }

  navigator.geolocation.getCurrentPosition(
    (resp) => {
      sessionStorage.setItem(UD_LAT, resp.coords.latitude.toString());
      sessionStorage.setItem(UD_LONG, resp.coords.longitude.toString());

      if (onSuccess) onSuccess();
    },
    (err) => {
      if (err.code === 1) {
        // denied permission
        errorLogger(
          true,
          `You denied us location permission: ${err.code} ${err.message}`,
          errorContext
        );
      }
      if (err.code === 2) {
        // couldn't get location
        errorLogger(true, `Could not get location: ${err.code} ${err.message}`, errorContext);
      }
      if (err.code === 3) {
        // timed out
        errorLogger(true, `Location timed out: ${err.code} ${err.message}`, errorContext);
      }
      if (onFailure) onFailure(err);
    }
  );
};

/**
 * Watch for permission changes (if a user already has location and refreshes, then the onchange binding goes away)
 */
export const watchLocationPermissionChanges = () => {
  try {
    navigator.permissions.query({ name: 'geolocation' }).then((result) => {
      // eslint-disable-next-line no-param-reassign
      result.onchange = () => {
        // if the user removes their location permissions, then clear the sessionStorage
        if (result.state !== 'granted') {
          sessionStorage.removeItem(UD_LAT);
          sessionStorage.removeItem(UD_LONG);
        }
      };
    });
  } catch (e) {
    // swallow
  }
};

/**
 * Return Country based on State
 */
export const stateProvinceToCountry = (stateProvince: string): string => {
  if (provincesArray.includes(stateProvince)) return 'CAN';
  switch (stateProvince) {
    case 'AS':
      return 'ASM';
    case 'GU':
      return 'GUM';
    case 'MP':
      return 'MNP';
    case 'PR':
      return 'PRI';
    case 'UM':
      return 'UMI';
    case 'VI':
      return 'VIR';
    default:
      return 'USA';
  }
};
