/* eslint-disable camelcase */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import * as branch from 'branch-sdk';
import Cookies from 'js-cookie';

import { UD_REF } from '@/utilities/constants';

const BranchContext = React.createContext<Branch>(null);

export interface BranchResponse extends branch.SessionData {
  data: string;
  data_parsed: {
    $og_title: string;
    $og_description: string;
    amount: string;
    object_id: string;
    promo_code: string;
    route: string;
    referral: string;
    username: string;
    '~referring_link': string;
  };
}

export interface BranchData {
  amount: string;
  description: string;
  objectId: string;
  promoCode: string;
  route: string;
  referral: string;
  referringLink: string;
  title: string;
  username: string;
}

export interface Branch {
  branchData: {
    data: BranchData;
    error: Error;
  };
  clearBranchData: () => void;
  setBranchData: ({ data, error }: { data: BranchData; error: Error }) => void;
}

const branchAdapter = (branchResponse: BranchResponse): BranchData => {
  if (!branchResponse) return null;
  const {
    $og_title,
    $og_description,
    amount,
    object_id,
    promo_code,
    route,
    referral,
    username,
    '~referring_link': referring_link,
  } = branchResponse?.data_parsed || {};

  return {
    title: $og_title,
    description: $og_description,
    amount,
    objectId: object_id,
    promoCode: promo_code,
    route,
    username,
    referral,
    referringLink: referring_link,
  };
};

interface BranchProviderProps {
  children: JSX.Element | JSX.Element[];
}

export const useBranch = (): [
  { data: BranchData; error: Error },
  React.Dispatch<React.SetStateAction<{ data: BranchData; error: Error }>>,
] => {
  const [branchData, setBranchData] = useState({ data: null, error: null });

  useEffect(() => {
    if (branch) {
      branch.data((error: string, data: BranchResponse) => {
        const adaptedData = branchAdapter(data);
        setBranchData({ data: adaptedData, error });
        if (adaptedData?.referringLink) {
          Cookies.set(UD_REF, adaptedData.referringLink, { expires: 2 / 24 }); // expire in 2 hours
        }
      });
    }
  }, []);

  return [branchData, setBranchData];
};

export const BranchProvider = (props: BranchProviderProps) => {
  const [branchData, setBranchData] = useBranch();

  const clearBranchData = useCallback(() => {
    setBranchData({ data: null, error: null });
  }, [setBranchData]);

  const memoBranchObject = useMemo(
    () => ({ branchData, clearBranchData, setBranchData }),
    [branchData, clearBranchData, setBranchData]
  );

  return <BranchContext.Provider value={memoBranchObject}>{props.children}</BranchContext.Provider>;
};

export default BranchContext;
