import React from 'react';

import Button from '@/components/atoms/button';
import SupportLink from '@/components/atoms/support-link';
import { UD_PICK_EM } from '@/utilities/constants';

import styles from './styles.scss';

const ErrorFallback = ({ error }: { error?: Error }) => (
  <div className={styles.errorFallback}>
    <h1 className={styles.title}>Woof!</h1>
    <p className={styles.message}>Something went wrong, try refreshing the page.</p>
    {error?.message ? <p className={styles.message}>{error.message}</p> : null}
    <p className={styles.message}>
      If the issue persists, please&nbsp;
      <SupportLink className={styles.message} label="contact support" />.
    </p>
    <Button
      width="intrinsic"
      size="regular"
      color="green"
      type="solid"
      onClick={() => {
        window.location.reload();
        localStorage.removeItem(UD_PICK_EM);
      }}
      text="Refresh"
    />
  </div>
);

export default ErrorFallback;
