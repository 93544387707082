import React, { useRef } from 'react';
import clsx from 'clsx';

import Label from '@/components/atoms/label';
import { HandleCloseModal, useModal } from '@/components/atoms/modal';
import ContentfulPromoInfo from '@/components/molecules/contentful-promo-info';
import CountdownLabel from '@/components/molecules/countdown-label';
import ModalWrapper from '@/components/molecules/modal-wrapper';
import OptionsWrapper from '@/components/molecules/options-wrapper';
import PickEmPlayerModal from '@/components/molecules/pick-em-player-modal';
import { ConstructedPickEmOverUnderLineAppearance } from '@/interfaces/constructed-interfaces/constructed-pick-em-over-under-appearance';
import { PickLocation } from '@/interfaces/pick-em';
import { CDN_HOST } from '@/utilities/constants';
import langHelper from '@/utilities/lang-helper';

import { usePromoCardBoostData } from './selectors';

import commonStyles from '../../common-styles.scss';
import styles from './styles.scss';

const FALLBACK_PLAYER_IMAGE = `${CDN_HOST}/pickem/placeholder-headshot.png`;

export interface PromoCardBoostProps {
  boostedLineId: string;
  constructedAppearance: ConstructedPickEmOverUnderLineAppearance;
  countdownExpiresAt: string | null;
  gameScheduledAt: string;
}

const PromoCardBoost = (props: PromoCardBoostProps) => {
  const { boostedLineId, constructedAppearance: cA, countdownExpiresAt, gameScheduledAt } = props;
  const openModal = useModal();
  const containerRef = useRef<HTMLDivElement>(null);

  const { overUnderLine, selectedOverUnders, teams } = usePromoCardBoostData({
    boostedLineId,
  });

  if (!cA || !overUnderLine || !selectedOverUnders || !teams) return null;

  const { boost } = overUnderLine.overUnder;

  const matchTime = langHelper.timeToDayOrDate(gameScheduledAt);

  const matchText = langHelper.genericMatchTextPickem({
    constructedAppearance: cA,
    teams,
    soloGame: cA?.soloGame,
  });

  const playerName = langHelper.getPlayerInitialAndLast(cA.player);

  const selectedOptionId = selectedOverUnders?.find((sOU) => {
    return sOU.overUnderLineId === overUnderLine.id;
  })?.option?.id;

  const openPromoInfoModal = () => {
    if (overUnderLine.status !== 'removed') {
      openModal(({ handleCloseModal }: HandleCloseModal) => (
        <ModalWrapper handleCloseModal={handleCloseModal}>
          <ContentfulPromoInfo
            handleCloseModal={handleCloseModal}
            contentfulInfoId={boost.contentfulInfoId}
          />
        </ModalWrapper>
      ));
    }
  };

  const openPlayerInfoModal = () => {
    if (overUnderLine.status !== 'removed') {
      openModal(({ handleCloseModal }: HandleCloseModal) => (
        <PickEmPlayerModal
          constructedAppearance={cA}
          handleCloseModal={handleCloseModal}
          isResults={false}
          sportId={cA.sport.id}
        />
      ));
    }
  };

  const keyboardSelect = (e: React.KeyboardEvent<HTMLElement>) => {
    if (containerRef.current === e.target) {
      if (e.key === ' ' || e.key === 'Enter') {
        e.preventDefault();
        openPlayerInfoModal();
      }
    }
  };

  return (
    <div
      ref={containerRef}
      className={clsx(commonStyles.promoCardWrapper, styles.promoCardBoost, {
        [styles.removed]: overUnderLine.status === 'removed',
      })}
      onClick={openPlayerInfoModal}
      onKeyDown={keyboardSelect}
      role="button"
      style={{ backgroundImage: `url(${CDN_HOST}/pickem/${boost.boostType || 'generic'}-bg.png)` }}
      tabIndex={0}
    >
      <img
        alt="player headshot"
        className={styles.playerImage}
        src={cA.player.imageUrl || FALLBACK_PLAYER_IMAGE}
      />
      <div className={styles.labelWrapper}>
        <Label
          classNames={{
            label: styles.label,
            text: styles.labelText,
          }}
          color="dark"
          iconNameLeft={boost.icon}
          onClick={
            boost.contentfulInfoId
              ? (e) => {
                  e.stopPropagation();
                  openPromoInfoModal();
                }
              : null
          }
          text={boost.displayTitle}
          type="primary"
        />
        {countdownExpiresAt && <CountdownLabel expiresAt={countdownExpiresAt} />}
      </div>
      <div className={clsx(commonStyles.infoSection, styles.infoSection)}>
        <h2 className={clsx(commonStyles.header, styles.header)}>{playerName}</h2>
        <p className={clsx(commonStyles.text, styles.text)}>
          {matchText} {matchTime}
        </p>
        <h2 className={clsx(commonStyles.header, styles.header)}>{overUnderLine.statValue}</h2>
        <p className={clsx(commonStyles.text, styles.text)}>
          {overUnderLine.overUnder.appearanceStat.displayStat}
        </p>
        <OptionsWrapper
          pickLocation={PickLocation.LOBBY}
          constructedAppearance={cA}
          overUnderLine={overUnderLine}
          selectedOptionId={selectedOptionId}
          swapLineOptionAndAnimate={false}
          swapStatValueAndAnimate={false}
          classNames={{
            selectedOverride: styles.selected,
            wrapper: styles.optionsButton,
          }}
        />
      </div>
    </div>
  );
};

export default PromoCardBoost;
