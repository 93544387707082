import {
  ConstructedPickEmRivalLine,
  ConstructedPickEmRivalLineAppearance,
  SharedSpread,
} from '@/interfaces/constructed-interfaces/constructed-pick-em-rival-appearance';
import { Appearance } from '@/interfaces/constructed-interfaces/constructed-slates';
import { LineupStatuses, Sports, Teams } from '@/interfaces/drafting-config';
import { ByeWeek, Matches } from '@/interfaces/matches';
import { FavoritePlayers, RivalLine, RivalOption, SoloGames } from '@/interfaces/pick-em';

export const pickEmRivalAppearanceConstructor = ({
  appearance,
  games,
  lineupStatuses,
  players,
  rivalOption,
  teams,
  sharedSpread,
  soloGames,
  sports,
}: {
  appearance: Appearance;
  games: Matches;
  soloGames: SoloGames;
  lineupStatuses: LineupStatuses;
  players: FavoritePlayers;
  rivalOption: RivalOption;
  sports: Sports;
  teams: Teams;
  sharedSpread?: SharedSpread;
}): ConstructedPickEmRivalLineAppearance => {
  const team = teams[appearance.teamId];
  const player = players[appearance.playerId];
  const sport = sports[player?.sportId];
  const lineupStatus = appearance.lineupStatusId ? lineupStatuses[appearance.lineupStatusId] : null;
  const match = appearance.matchType === 'Game' ? games[appearance?.matchId] : null;
  const soloGame = appearance.matchType === 'SoloGame' ? soloGames[appearance?.matchId] : null;
  const byeWeek = match && match.byeWeeks ? match.byeWeeks[appearance.teamId] : (null as ByeWeek);

  return {
    id: appearance.id,
    byeWeek,
    latestNewsItemUpdatedAt: appearance.latestNewsItemUpdatedAt,
    lineupStatus,
    match,
    matchType: appearance.matchType,
    rivalOption,
    player,
    projection: appearance.projection,
    positionId: appearance.positionId,
    statLine: appearance.statLine,
    score: appearance.score,
    soloGame,
    sport,
    team,
    sharedSpread,
  };
};

export const pickEmRivalConstructor = ({
  appearances,
  games,
  lineupStatuses,
  players,
  rivalLine,
  soloGames,
  sports,
  teams,
}: {
  appearances: Appearance[];
  games: Matches;
  lineupStatuses: LineupStatuses;
  players: FavoritePlayers;
  rivalLine: RivalLine;
  soloGames: SoloGames;
  sports: Sports;
  teams: Teams;
}): ConstructedPickEmRivalLine => {
  const optionsBySpread = rivalLine.options.sort(
    (a, b) => parseFloat(b.spread) - parseFloat(a.spread)
  );
  // appearance left has no spread and is displayed in the left Cell
  const appearanceLeft = appearances.find(
    (app) => app.id === optionsBySpread[0]?.appearanceStat.appearanceId
  );
  // appearance right has a spread handicap and is displayed in the right Cell
  const appearanceRight = appearances.find(
    (app) => app.id === optionsBySpread[1]?.appearanceStat.appearanceId
  );

  const appearanceOptions = [appearanceLeft, appearanceRight].map((appearance, index) =>
    pickEmRivalAppearanceConstructor({
      appearance,
      games,
      lineupStatuses,
      players,
      rivalOption: optionsBySpread[index], // this could be a little fragile, it may need fixing
      soloGames,
      sports,
      teams,
    })
  );

  return {
    id: rivalLine.id,
    liveEvent: rivalLine.liveEvent,
    liveEventStats: rivalLine.liveEventStats,
    appearanceOptions,
    rank: rivalLine.rank,
    rival: rivalLine.rival,
    rivalId: rivalLine.rivalId,
    rivalLineId: rivalLine.id,
    statValue: rivalLine.statValue,
    status: rivalLine.status,
  };
};

export const pickEmRivalLineConstructor = ({
  appearances,
  games,
  lineupStatuses,
  players,
  rivalLines,
  soloGames,
  sports,
  teams,
}: {
  appearances: Appearance[];
  games: Matches;
  lineupStatuses: LineupStatuses;
  players: FavoritePlayers;
  rivalLines: RivalLine[];
  soloGames: SoloGames;
  sports: Sports;
  teams: Teams;
}): ConstructedPickEmRivalLine[] =>
  rivalLines.map((rivalLine) =>
    pickEmRivalConstructor({
      appearances,
      games,
      lineupStatuses,
      players,
      rivalLine,
      soloGames,
      sports,
      teams,
    })
  );
