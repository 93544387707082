/* eslint-disable camelcase */
import powerUpAdapter from '@/store/modules/power-ups/adapters';

import { Selection, SelectionResponse } from '@/interfaces/pick-em';

const selectionAdapter = (selection: SelectionResponse): Selection => {
  const {
    id,
    in_play,
    result,
    option_id,
    option_type,
    actual_stat_value,
    power_up,
    rival_actual_stat_value,
    rebooted,
  } = selection;

  const adaptedPowerUp = power_up ? powerUpAdapter(power_up) : null;

  return {
    id,
    inPlay: in_play,
    result,
    optionId: option_id,
    optionType: option_type,
    actualStatValue: actual_stat_value,
    powerUp: adaptedPowerUp,
    rebooted,
    rivalActualStatValue: rival_actual_stat_value,
  };
};

export default selectionAdapter;
