import { combineReducers } from 'redux';

import { activeDraftsReducer } from '@/store/modules/active-drafts/reducer';
import { appearanceStatsReducer } from '@/store/modules/appearance-stats/reducer';
import { appearanceStatsWkAvgReducer } from '@/store/modules/appearance-stats-wk-avg/reducer';
import { completedDraftsReducer } from '@/store/modules/completed-drafts/reducer';
import { completedSlatesReducer } from '@/store/modules/completed-slates/reducer';
import { constructedSlatesReducer } from '@/store/modules/constructed-slates/reducer';
import { contentfulPowerUpInfosReducer } from '@/store/modules/contentful-power-up-infos/reducer';
import { contentfulPromoInfosReducer } from '@/store/modules/contentful-promo-info/reducer';
import { cosmeticsAndAchievementsReducer } from '@/store/modules/cosmetics/reducer';
import { draftPoolsReducer } from '@/store/modules/draft-pools/reducer/draft-pools';
import { draftPoolsForSlateReducer } from '@/store/modules/draft-pools/reducer/draft-pools-for-slate';
import { userEntriesForDraftPoolsReducer } from '@/store/modules/draft-pools/reducer/user-entries-for-draft-pools';
import { draftingConfigReducer } from '@/store/modules/drafting-config/reducer';
import { draftWeeklyScoresReducer } from '@/store/modules/drafts/reducer/draft-weekly-scores';
import { draftsReducer } from '@/store/modules/drafts/reducer/drafts';
import { errorReducer } from '@/store/modules/error/reducer';
import { exposuresReducer } from '@/store/modules/exposure/reducer';
import { featureFlagsReducer } from '@/store/modules/feature-flags/reducer';
import { generalRulesReducer } from '@/store/modules/general-rules/reducer';
import { landingPageConfigsReducer } from '@/store/modules/landing-page-configs/reducer';
import { liveDraftsForSlateReducer } from '@/store/modules/live/reducer/live-drafts-for-slate';
import { liveSlatesForBestBallSportReducer } from '@/store/modules/live/reducer/live-slates-for-best-ball-sport';
import { liveSlatesForDailySportReducer } from '@/store/modules/live/reducer/live-slates-for-daily-sport';
import { mainLobbyReducer } from '@/store/modules/lobbies/reducer';
import { sportLobbyReducer } from '@/store/modules/lobbies/reducer/sport-lobby';
import { dateMatchesReducer } from '@/store/modules/matches/reducer';
import { pickEmOverUnderLineReducer } from '@/store/modules/pick-em/reducer/over-under-line';
import { pickEmEntriesReducer } from '@/store/modules/pick-em/reducer/pick-em-entries';
import { pickEmRivalLineReducer } from '@/store/modules/pick-em/reducer/rivals';
import { userPromotionsReducer } from '@/store/modules/promotions/reducer';
import { settledDraftsForSlatesReducer } from '@/store/modules/results/reducer/settled-drafts-for-slate';
import { settledSlatesReducer } from '@/store/modules/results/reducer/settled-slates';
import { sitAndGosReducer } from '@/store/modules/sit-and-gos/reducer';
import { sportsRulesReducer } from '@/store/modules/sport-rules/reducer';
import { stateConfigurationReducer } from '@/store/modules/state-configuration/reducer';
import { sweepstakesTermsAndConditionsReducer } from '@/store/modules/sweepstakes-terms-and-conditions/reducer';
import { textPagesReducer } from '@/store/modules/text-pages/reducer';
import { toastReducer } from '@/store/modules/toast/reducer';
import { paginatedDraftEntriesForDraftReducer } from '@/store/modules/tournament-rounds/reducer/draft-entries-for-draft';
import { draftsForTournamentRoundReducer } from '@/store/modules/tournament-rounds/reducer/drafts-for-tournament-rounds';
import { tournamentRoundsForSlateReducer } from '@/store/modules/tournament-rounds/reducer/tournament-rounds';
import { userPaginatedDraftEntriesForDraftReducer } from '@/store/modules/tournament-rounds/reducer/user-draft-entries-for-draft';
import { tournamentsRulesReducer } from '@/store/modules/tournament-rules/reducer';
import { tournamentsReducer } from '@/store/modules/tournaments/reducer';
import { transactionsReducer } from '@/store/modules/transactions/reducer';
import { underdogCareersFaqReducer } from '@/store/modules/underdog-careers-faqs/reducer';
import { underdogTeamReducer } from '@/store/modules/underdog-team/reducer';
import { SET_USER, setUser } from '@/store/modules/user/actions';
import { userReducer } from '@/store/modules/user/reducer';
import { weeklyAppearancesReducer } from '@/store/modules/weekly-appearances/reducer';
import { weeklyMatchesReducer } from '@/store/modules/weekly-matches/reducer';
import { weeklyWinnersReducer } from '@/store/modules/weekly-winners/reducer';
import { draftsForWeeklyWinnersReducer } from '@/store/modules/weekly-winners/reducer/drafts-for-weekly-winners';
import { userEntriesForWeeklyWinnerWeeksReducer } from '@/store/modules/weekly-winners/reducer/user-entries-for-weekly-winner-weeks';
import { userLeaderboardMarkersForWeeklyWinnerWeeksReducer } from '@/store/modules/weekly-winners/reducer/user-leaderboard-markers-for-weekly-winner-weeks';
import { weeklyWinnerWeeksReducer } from '@/store/modules/weekly-winners/reducer/weekly-winner-weeks';
import { weeklyWinnersForSlateReducer } from '@/store/modules/weekly-winners/reducer/weekly-winners-for-slate';

const appReducer = combineReducers({
  activeDrafts: activeDraftsReducer,
  appearanceStats: appearanceStatsReducer,
  appearanceStatsWkAvg: appearanceStatsWkAvgReducer,
  contentfulPowerUpInfos: contentfulPowerUpInfosReducer,
  contentfulPromoInfos: contentfulPromoInfosReducer,
  completedDraftsBySlate: completedDraftsReducer,
  completedSlates: completedSlatesReducer,
  constructedSlates: constructedSlatesReducer,
  cosmeticsAndAchievements: cosmeticsAndAchievementsReducer,
  dateMatches: dateMatchesReducer,
  draftPools: draftPoolsReducer,
  draftPoolsForSlate: draftPoolsForSlateReducer,
  draftingConfig: draftingConfigReducer,
  drafts: draftsReducer,
  draftsForTournamentRound: draftsForTournamentRoundReducer,
  draftsForWeeklyWinners: draftsForWeeklyWinnersReducer,
  draftWeeklyScores: draftWeeklyScoresReducer,
  error: errorReducer,
  exposure: exposuresReducer,
  featureFlags: featureFlagsReducer,
  generalRules: generalRulesReducer,
  landingPageConfigs: landingPageConfigsReducer,
  liveDraftsForSlate: liveDraftsForSlateReducer,
  liveSlatesForBestBallSport: liveSlatesForBestBallSportReducer,
  liveSlatesForDailySport: liveSlatesForDailySportReducer,
  mainLobby: mainLobbyReducer,
  paginatedDraftEntriesForDraft: paginatedDraftEntriesForDraftReducer,
  pickEmEntries: pickEmEntriesReducer,
  pickEmOverUnder: pickEmOverUnderLineReducer,
  pickEmRivals: pickEmRivalLineReducer,
  settledDraftsForSlate: settledDraftsForSlatesReducer,
  settledSlates: settledSlatesReducer,
  sitAndGos: sitAndGosReducer,
  sportLobby: sportLobbyReducer,
  sportsRules: sportsRulesReducer,
  stateConfigurations: stateConfigurationReducer,
  sweepstakesTermsAndConditions: sweepstakesTermsAndConditionsReducer,
  textPages: textPagesReducer,
  toast: toastReducer,
  tournamentRoundsForSlate: tournamentRoundsForSlateReducer,
  tournamentsRules: tournamentsRulesReducer,
  tournaments: tournamentsReducer,
  transactions: transactionsReducer,
  underdogCareersFaqs: underdogCareersFaqReducer,
  underdogTeam: underdogTeamReducer,
  user: userReducer,
  userEntriesForDraftPools: userEntriesForDraftPoolsReducer,
  userEntriesForWeeklyWinnerWeeks: userEntriesForWeeklyWinnerWeeksReducer,
  userPaginatedDraftEntriesForDraft: userPaginatedDraftEntriesForDraftReducer,
  userPromotion: userPromotionsReducer,
  weeklyAppearances: weeklyAppearancesReducer,
  weeklyMatches: weeklyMatchesReducer,
  userLeaderboardMarkersForWeeklyWinnerWeeks: userLeaderboardMarkersForWeeklyWinnerWeeksReducer,
  weeklyWinnerWeeks: weeklyWinnerWeeksReducer,
  weeklyWinners: weeklyWinnersReducer,
  weeklyWinnersForSlate: weeklyWinnersForSlateReducer,
});

const rootReducer = (
  state: any,
  action: ReturnType<typeof setUser>
): ReturnType<typeof appReducer> => {
  // clear out state if logging out
  if (action.type === SET_USER && !action.payload) {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
