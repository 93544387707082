import React from 'react';
import clsx from 'clsx';

import styles from './styles.scss';

export interface ProgressBarSteppedProps {
  classNames?: {
    wrapper?: string;
    emptyBar?: string;
    fullBar?: string;
  };
  currentCount: number;
  totalCount: number;
}

const ProgressBarStepped = (props: ProgressBarSteppedProps) => {
  const { classNames, currentCount, totalCount } = props;

  const bars = [];

  // eslint-disable-next-line no-plusplus
  for (let i = 1; i <= totalCount; i++) {
    if (i <= currentCount) {
      bars.push(<div key={i} className={clsx(styles.bar, styles.fullBar, classNames.fullBar)} />);
    } else {
      bars.push(<div key={i} className={clsx(styles.bar, styles.emptyBar, classNames.emptyBar)} />);
    }
  }

  return <div className={clsx(styles.progressBar, classNames.wrapper)}>{bars}</div>;
};

ProgressBarStepped.defaultProps = {
  classNames: {},
};

export default ProgressBarStepped;
