import React from 'react';

import Icon from '@/components/atoms/icon';
import SupportLink from '@/components/atoms/support-link';

import styles from './styles.scss';

export const ErrorState = () => {
  return (
    <div className={styles.errorState}>
      <Icon name="detailedError" className={styles.errorIcon} />
      <p className={styles.header}>Something went wrong.</p>
      <p className={styles.text}>
        Oops! We&apos;re sorry, there&apos;s an issue on our end. Please refresh or{' '}
        <SupportLink label="contact support" />.
      </p>
    </div>
  );
};

export default ErrorState;
