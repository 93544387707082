/* eslint-disable camelcase */
import { Pack, PacksResponseItem } from '@/interfaces/pick-em';

const pickEmPackAdapter = (pack: PacksResponseItem): Pack => {
  const { id, pack_title, rank, over_under_lines } = pack;

  const adaptedOverUnderLines = over_under_lines.map((oUL) => ({
    lineId: oUL.line_id,
    optionId: oUL.option_id,
  }));

  return {
    id,
    packTitle: pack_title,
    rank,
    overUnderLines: adaptedOverUnderLines,
    status: 'active',
  };
};

export default pickEmPackAdapter;
