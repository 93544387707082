import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import { RootState } from '@/store';

import ExternalLink from '@/components/atoms/external-link';
import Icon from '@/components/atoms/icon';
import SupportLink from '@/components/atoms/support-link';
import { ThemeContext } from '@/components/contexts/theme';
import { CDN_HOST } from '@/utilities/constants';

import styles from './styles.scss';

const PayShiftingContent = () => (
  <div className={styles.content} key="payoutModifierContent">
    <h2>Why do payouts get modified?</h2>
    <p>
      In order to bring you the widest variety of projections, certain picks and pick combinations
      will impact your payout amount.
    </p>
    <p className={styles.rulesLink}>
      <ExternalLink
        href="https://underdogfantasy.com/rules/pick-em#modified%20payouts"
        text="Learn more"
        showArrow
      />
    </p>
  </div>
);

const BoostContent = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <div className={styles.content} key="boostContent">
      <h2>Boosts</h2>
      <p>
        Boosts are picks which increase your max payout and return any winnings over the standard
        multipliers as bonus cash.
      </p>
      <img
        src={`${CDN_HOST}/pickem/boosts-illustration_${theme}.svg`}
        alt="Boosts illustration"
        className={styles.modalIllustration}
      />
      <h2>Boosted payouts</h2>
      <p>
        Payouts over the standard multipliers will be awarded as bonus cash and must be played
        through in order to be withdrawn.
      </p>
      <p>
        For example, if a Boost multiplier offer is for 50x for making 5 picks, and the regular
        multiplier is 20x—the additional 30x will be awarded as bonus cash.
      </p>
    </div>
  );
};

const Insurance = () => {
  const isWebPick8Enabled = useSelector((state: RootState) => state.featureFlags.webPick8);

  return (
    <div className={styles.content} key="insuranceContent">
      <h2>What is flex?</h2>
      <p>
        Entries of 3 or more picks can be protected at a reduced multiplier and will pay out, even
        with 1 incorrect pick. Standard entries have higher multipliers and payouts but require all
        picks to be correct.
      </p>
      {isWebPick8Enabled && (
        <p>Entries with 6, 7 or 8 picks require double-flex, protecting two incorrect picks.</p>
      )}
      <h2>What happens if my flexed entry has ties or voids?</h2>
      <p>
        Multiple Ties/Voids within an entry will move the payout down accordingly. For example, a
        flexed 5-pick entry with 2 Ties/Voids will revert down to a flexed 3-pick entry.
      </p>
      <p>
        Entries that are reverted down to include only players on one team or just a single player
        will be void and refunded.
      </p>
      {!isWebPick8Enabled ? (
        <>
          <h2>Are any picks excluded from being flexed?</h2>
          <p>Entries containing boosted or special picks cannot be flexed.</p>
        </>
      ) : null}
      <h2>Have questions?</h2>
      <p className={styles.rulesLink}>
        <SupportLink label="Contact support" />
      </p>
    </div>
  );
};

const Scorchers = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <div className={styles.content} key="scorchersContent">
      <h2>What are scorchers?</h2>
      <p>Scorchers are picks which increase your max payout. Spicy!</p>
      <img
        src={`${CDN_HOST}/pickem/scorchers-illustration_${theme}.svg`}
        alt="Scorchers illustration"
        className={styles.modalIllustration}
      />
      <h2>Scorched payouts</h2>
      <p>
        A scorcher must be correct to receive the payout increase. If your scorcher voids or ties,
        you will not receive the increase tied to that scorcher, even if your entry was flexed.
      </p>
    </div>
  );
};

const RescuesStandard = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <div className={styles.content} key="rescuesContent">
      <img
        src={`${CDN_HOST}/pickem/rescues_${theme}.png`}
        alt="Rescues"
        className={styles.modalIllustration}
      />
      <h2>What are Rescues?</h2>
      <p>
        Rescues protect you against your players not playing in the 2nd half of games as a result of
        injury, ejection, etc.
      </p>
      <h2>How does it work?</h2>
      <p>
        If a player in your entry plays in the first half but does not return in the second half for
        any reason (injury, ejection, etc.), your entry can be refunded and receive a special to use
        the following day.
      </p>
      <p>
        All other selections in your entry must be graded as a win, tie or void to be eligible for
        injury coverage. Your entry is not eligible if any picks are graded as a loss.
      </p>
      <p>Partial game picks are not eligible for injury coverage.</p>
      <p className={styles.rulesLink}>
        <ExternalLink
          href="https://underdogfantasy.com/rules/pick-em"
          text="Learn more"
          showArrow
        />
      </p>
    </div>
  );
};

const RescuesChampions = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <div className={styles.content} key="rescuesContent">
      <img
        src={`${CDN_HOST}/pickem/rescues_${theme}.png`}
        alt="Rescues"
        className={styles.modalIllustration}
      />
      <h2>What are Rescues?</h2>
      <p>
        Rescues protect you against your players not playing in the 2nd half of games as a result of
        injury, ejection, etc.
      </p>
      <h2>How does it work?</h2>
      <p>
        If a player in your entry plays in the first half but does not return in the second half for
        any reason (injury, ejection, etc.), your entry can be refunded.
      </p>
      <p>
        All other selections in your entry must be graded as a win, tie or void to be eligible for
        injury coverage. Your entry is not eligible if any picks are graded as a loss.
      </p>
      <p>Partial game picks are not eligible for injury coverage.</p>
      <p className={styles.rulesLink}>
        <ExternalLink
          href="https://underdogfantasy.com/rules/pick-em"
          text="Learn more"
          showArrow
        />
      </p>
    </div>
  );
};

const defaultContent = () => (
  <div className={styles.content} key="defaultContent">
    <h2>Have questions?</h2>
    <p className={styles.rulesLink}>
      <SupportLink label="Contact support" />
    </p>
  </div>
);

export const PayoutMatrix = () => {
  return (
    <div className={styles.content} key="payoutMatrixContent">
      <h2>Payout matrix</h2>
      <p>
        Here&apos;s how much you could win from each entry combo. Entries with 6+ picks are
        automatically flexed and allow you to miss up to 2 picks.
      </p>
      <div className={styles.tableContainer}>
        <div className={styles.fixedTableContainer}>
          <table className={styles.payoutMatrix}>
            <thead>
              <tr>
                <th>
                  <div className={styles.pickCountColumnHeader}>
                    <p>Pick</p>
                    <p>Count</p>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>2</td>
              </tr>
              <tr>
                <td>3</td>
              </tr>
              <tr>
                <td>4</td>
              </tr>
              <tr>
                <td>5</td>
              </tr>
              <tr>
                <td>6</td>
              </tr>
              <tr>
                <td>7</td>
              </tr>
              <tr>
                <td>8</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className={styles.scrollableTableContainer}>
          <table className={styles.payoutMatrix}>
            <thead>
              <tr>
                <th colSpan={2} className={styles.flexData}>
                  Standard Payout
                </th>
                <th className={styles.borderColumn} />
                <th colSpan={4} className={styles.flexHeader}>
                  <div className={styles.iconHeaderContainer}>
                    <Icon className={styles.flexIcon} name="insurance" />
                    Flexed payout
                  </div>
                </th>
              </tr>
              <tr>
                <th>Missed 0</th>
                <th className={clsx(styles.scorcherColumn, styles.flexData)}>
                  <div className={styles.iconHeaderContainer}>
                    <Icon name="detailedPepper" className={styles.scorcherIcon} />
                    Scorchers
                  </div>
                </th>
                <th className={styles.borderColumn} />
                <th>Missed 0</th>
                <th>Missed 1</th>
                <th>Missed 2</th>
                <th className={styles.scorcherColumn}>
                  <div className={styles.iconHeaderContainer}>
                    <Icon name="detailedPepper" className={styles.scorcherIcon} />
                    Scorchers
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>3x</td>
                <td className={clsx(styles.flexData, styles.scorcherCell)}>100x</td>
                <td className={styles.borderColumn} />
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr>
                <td>6x</td>
                <td className={clsx(styles.flexData, styles.scorcherCell)}>150x</td>
                <td className={styles.borderColumn} />
                <td>3x</td>
                <td>1x</td>
                <td>-</td>
                <td className={styles.scorcherCell}>75x</td>
              </tr>
              <tr>
                <td>10x</td>
                <td className={clsx(styles.flexData, styles.scorcherCell)}>200x</td>
                <td className={styles.borderColumn} />
                <td>6x</td>
                <td>1.5x</td>
                <td>-</td>
                <td className={styles.scorcherCell}>100x</td>
              </tr>
              <tr>
                <td>20x</td>
                <td className={clsx(styles.flexData, styles.scorcherCell)}>250x</td>
                <td className={styles.borderColumn} />
                <td>10x</td>
                <td>2.5x</td>
                <td>-</td>
                <td className={styles.scorcherCell}>125x</td>
              </tr>
              <tr>
                <td>-</td>
                <td className={styles.flexData}>-</td>
                <td className={styles.borderColumn} />
                <td>25x</td>
                <td>2.6x</td>
                <td>0.25x</td>
                <td className={styles.scorcherCell}>175x</td>
              </tr>
              <tr>
                <td>-</td>
                <td className={styles.flexData}>-</td>
                <td className={styles.borderColumn} />
                <td>40x</td>
                <td>2.75x</td>
                <td>0.5x</td>
                <td className={styles.scorcherCell}>250x</td>
              </tr>
              <tr>
                <td>-</td>
                <td className={styles.flexData}>-</td>
                <td className={styles.borderColumn} />
                <td>80x</td>
                <td>3x</td>
                <td>1x</td>
                <td className={styles.scorcherCell}>325x</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export const contentMap = {
  boost: BoostContent,
  default: defaultContent,
  insurance: Insurance,
  payoutMatrix: PayoutMatrix,
  payoutShifting: PayShiftingContent,
  scorchers: Scorchers,
  rescuesStandard: RescuesStandard,
  rescuesChampions: RescuesChampions,
};
