/* eslint-disable camelcase */
import { OverUnderLine, OverUnderLineResponse } from '@/interfaces/pick-em';

import overUnderAdapter from './over-under';
import overUnderOptionAdapter from './over-under-option';

const overUnderLineAdapter = (overUnderLine: OverUnderLineResponse): OverUnderLine => {
  const {
    id,
    expires_at,
    live_event,
    live_event_stat,
    options: optionsResponse,
    over_under: overUnderResponse,
    over_under_id,
    rank,
    stat_value,
    status,
  } = overUnderLine;

  const options = optionsResponse ? optionsResponse?.map(overUnderOptionAdapter) : null;

  const overUnder = overUnderResponse ? overUnderAdapter(overUnderResponse) : null;

  const splitLine = options?.length > 1 && options.some((option) => option.payoutMultiplier !== 1);

  return {
    id,
    expiresAt: expires_at,
    liveEvent: live_event,
    liveEventStat: live_event_stat,
    options,
    overUnder,
    overUnderId: over_under_id,
    rank,
    statValue: stat_value,
    status,
    splitLine,
  };
};

export default overUnderLineAdapter;
