import { EntrySlipState, PickEmEntries } from '@/interfaces/pick-em';

import {
  acceptSelectedOverUnderLineSwap,
  addFavoritePickEmPlayer,
  CLEAR_PICK_EM_ENTRY_SLIP,
  clearPickEmEntrySlip,
  REMOVE_SELECTED_ERROR,
  removeAirdropOffer,
  removeFavoritePickEmPlayer,
  removePickEmOverUnderLine,
  removeSelectedPickEmBoost,
  removeSelectedPickEmError,
  RESET_PICK_EM_POOL_LEADERBOARD,
  RESET_PICK_EM_POOL_USER_LEADERBOARD,
  RESET_SETTLED_PICK_EM_ENTRIES,
  resetPickEmPoolLeaderboard,
  resetPickEmPoolUserLeaderboard,
  resetSettledPickEmEntries,
  SET_ENTRY_SLIP_LIMIT,
  SET_INITIAL_PICK_EM_SELECTIONS,
  SET_LIVE_PICK_EM_ENTRIES,
  SET_PAYOUT_OUTCOME,
  SET_PICK_EM_POOL_LEADERBOARD,
  SET_PICK_EM_POOL_USER_LEADERBOARD,
  SET_POOL_V1_STYLES,
  SET_POOL_V2_STYLES,
  SET_POWER_UP_ON_ENTRY,
  SET_POWER_UP_ON_SELECTED_OVER_UNDER,
  SET_SETTLED_PICK_EM_ENTRIES,
  setEntrySlipLimits,
  setInitialPickEmSelections,
  setLivePickEmEntries,
  setPickEmPoolLeaderboard,
  setPickEmPoolUserLeaderboard,
  setPoolV1Styles as setPoolV1StylesAction,
  setPoolV2Styles as setPoolV2StylesAction,
  setPowerUpOnEntry as setPowerUpOnEntryAction,
  setPowerUpOnSelectedOverUnder as setPowerUpOnSelectedOverUnderAction,
  setSettledPickEmEntries,
  setSlipPayoutOutcome,
  SWAP_PICK_EM_OVER_UNDER_LINE,
  swapFinishedPickEmOverUnderLine,
  swapPickEmOverUnderLine,
  TOGGLE_PICK_EM_INSURANCE,
  togglePickEmInsurance,
  UPDATE_LIVE_PICK_EM_GAME,
  UPDATE_LIVE_PICK_EM_SOLO_GAME,
  UPDATE_LIVE_PICK_EM_STAT_LINE,
  UPDATE_PICK_EM_OVER_UNDER_LINE_STATUS,
  UPDATE_PICKS_FROM_SHARE_SLIP,
  UPDATE_SELECTED_OVER_UNDERS,
  UPDATE_SELECTED_RIVALS,
  updateLivePickEmGame,
  updateLivePickEmSoloGame,
  updateLivePickEmStatLine,
  updatePickEmLiveEventStat,
  updatePickEmOverUnderLineStatus,
  updateSelectedOverUnders,
  updateSelectedPicksFromSlip,
  updateSelectedRivals,
} from '../actions';

import {
  setLimits,
  setPayoutOutcomeToState,
  setPickEmEntries,
  setPoolV1Styles,
  setPoolV2Styles,
  setSelectedPickEmEntries,
} from './helpers/set-pick-em-entries';
import {
  clearEntrySlip,
  removeErr,
  setPowerUpOnEntry,
  setPowerUpOnSelectedOverUnder,
  swapSelectedOverUnderLine,
  toggleInsurance,
  updateLiveGame,
  updateLiveSoloGame,
  updateLiveStatLine,
  updateOverUnders,
  updatePicksFromSlip,
  updateRivals,
  updateSelectedOverUnderLineStatus,
} from './helpers/update-pick-em-entries';

type State = PickEmEntries;

type PickEmEntriesActions = ReturnType<
  | typeof acceptSelectedOverUnderLineSwap
  | typeof addFavoritePickEmPlayer
  | typeof clearPickEmEntrySlip
  | typeof removeAirdropOffer
  | typeof removeFavoritePickEmPlayer
  | typeof removePickEmOverUnderLine
  | typeof removeSelectedPickEmError
  | typeof removeSelectedPickEmBoost
  | typeof resetPickEmPoolLeaderboard
  | typeof resetPickEmPoolUserLeaderboard
  | typeof resetSettledPickEmEntries
  | typeof setEntrySlipLimits
  | typeof setInitialPickEmSelections
  | typeof setLivePickEmEntries
  | typeof setPickEmPoolLeaderboard
  | typeof setPickEmPoolUserLeaderboard
  | typeof setPoolV1StylesAction
  | typeof setPoolV2StylesAction
  | typeof setPowerUpOnEntryAction
  | typeof setPowerUpOnSelectedOverUnderAction
  | typeof setSettledPickEmEntries
  | typeof setSlipPayoutOutcome
  | typeof swapFinishedPickEmOverUnderLine
  | typeof swapPickEmOverUnderLine
  | typeof togglePickEmInsurance
  | typeof updateLivePickEmGame
  | typeof updateLivePickEmSoloGame
  | typeof updateLivePickEmStatLine
  | typeof updateSelectedOverUnders
  | typeof updateSelectedPicksFromSlip
  | typeof updateSelectedRivals
  | typeof updatePickEmOverUnderLineStatus
  | typeof updatePickEmLiveEventStat
>;

export const initialPickEmEntriesState: PickEmEntries = {
  leaderboard: {
    appearanceIds: null,
    gameIds: null,
    soloGameIds: null,
    appearances: null,
    entrySlips: null,
    overUnderOptions: null,
    overUnderLines: null,
    overUnders: null,
    games: null,
    players: null,
    rivalLines: null,
    rivalOptions: null,
    rivals: null,
    soloGames: null,
    liveStatLines: null,
    liveGames: null,
    meta: null,
    lastUpdated: null,
  },
  live: {
    appearanceIds: null,
    gameIds: null,
    soloGameIds: null,
    appearances: null,
    entrySlips: null,
    overUnderOptions: null,
    overUnderLines: null,
    overUnders: null,
    games: null,
    players: null,
    rivalLines: null,
    rivalOptions: null,
    rivals: null,
    soloGames: null,
    liveStatLines: null,
    liveGames: null,
    meta: null,
    lastUpdated: null,
  },
  selected: {
    selectedOverUnders: [],
    selectedRivals: [],
    entrySlipLimits: null,
    error: null,
    loaded: false,
    poolV1Style: null,
    poolV2Style: null,
    powerUp: null,
    slipType: null,
  },
  settled: {
    appearanceIds: null,
    gameIds: null,
    soloGameIds: null,
    appearances: null,
    entrySlips: null,
    overUnderOptions: null,
    overUnderLines: null,
    overUnders: null,
    games: null,
    players: null,
    rivalLines: null,
    rivalOptions: null,
    rivals: null,
    soloGames: null,
    liveStatLines: null,
    liveGames: null,
    meta: null,
    lastUpdated: null,
  },
  payoutOutcome: {
    errors: [],
    insurance: null,
    lastUpdated: null,
    pickEmPool: {
      entryRole: null,
      maxFee: '-',
      rake: null,
      styleId: null,
      totalFees: null,
    },
    standard: null,
    selectionCount: null,
  },
  prevPayoutOutcome: null,
  poolV1Styles: null,
  poolV2Styles: null,
  userLeaderboard: {
    appearanceIds: null,
    gameIds: null,
    soloGameIds: null,
    appearances: null,
    entrySlips: null,
    overUnderOptions: null,
    overUnderLines: null,
    overUnders: null,
    games: null,
    players: null,
    rivalLines: null,
    rivalOptions: null,
    rivals: null,
    soloGames: null,
    liveStatLines: null,
    liveGames: null,
    meta: null,
    lastUpdated: null,
  },
};

export const resetPickEmEntries = (
  state: PickEmEntries,
  entrySlipState: EntrySlipState
): PickEmEntries => ({
  ...state,
  [entrySlipState]: {
    ...initialPickEmEntriesState[entrySlipState],
    lastUpdated: new Date(),
  },
});

export const pickEmEntriesReducer = (
  state: State = initialPickEmEntriesState,
  action: PickEmEntriesActions = {} as PickEmEntriesActions
): State => {
  switch (action.type) {
    // set methods
    case SET_PAYOUT_OUTCOME:
      return setPayoutOutcomeToState(state, action.payload);
    case SET_POOL_V1_STYLES:
      return setPoolV1Styles(state, action.payload);
    case SET_POOL_V2_STYLES:
      return setPoolV2Styles(state, action.payload);
    case SET_LIVE_PICK_EM_ENTRIES:
      return setPickEmEntries(state, action.payload, 'live');
    case SET_SETTLED_PICK_EM_ENTRIES:
      return setPickEmEntries(state, action.payload, 'settled');
    case RESET_SETTLED_PICK_EM_ENTRIES:
      return resetPickEmEntries(state, 'settled');
    case SET_INITIAL_PICK_EM_SELECTIONS:
      return setSelectedPickEmEntries(state, action.payload);
    case SET_ENTRY_SLIP_LIMIT:
      return setLimits(state, action.payload);
    case SET_PICK_EM_POOL_LEADERBOARD:
      return setPickEmEntries(state, action.payload, 'leaderboard');
    case SET_PICK_EM_POOL_USER_LEADERBOARD:
      return setPickEmEntries(state, action.payload, 'userLeaderboard');
    case RESET_PICK_EM_POOL_LEADERBOARD:
      return resetPickEmEntries(state, 'leaderboard');
    case RESET_PICK_EM_POOL_USER_LEADERBOARD:
      return resetPickEmEntries(state, 'userLeaderboard');

    // live
    case UPDATE_LIVE_PICK_EM_STAT_LINE:
      return updateLiveStatLine(state, action.payload);
    case UPDATE_LIVE_PICK_EM_GAME:
      return updateLiveGame(state, action.payload);
    case UPDATE_LIVE_PICK_EM_SOLO_GAME:
      return updateLiveSoloGame(state, action.payload);

    // updates
    case SWAP_PICK_EM_OVER_UNDER_LINE:
      return swapSelectedOverUnderLine(state, action.payload);

    // selected
    case UPDATE_SELECTED_OVER_UNDERS:
      return updateOverUnders(state, action.payload);
    case UPDATE_PICK_EM_OVER_UNDER_LINE_STATUS:
      return updateSelectedOverUnderLineStatus(state, action.payload);
    case UPDATE_PICKS_FROM_SHARE_SLIP:
      return updatePicksFromSlip(state, action.payload);
    case UPDATE_SELECTED_RIVALS:
      return updateRivals(state, action.payload);
    case CLEAR_PICK_EM_ENTRY_SLIP:
      return clearEntrySlip(state);
    case REMOVE_SELECTED_ERROR:
      return removeErr(state);
    case TOGGLE_PICK_EM_INSURANCE:
      return toggleInsurance(state, action.payload);
    case SET_POWER_UP_ON_ENTRY:
      return setPowerUpOnEntry(state, action.payload);
    case SET_POWER_UP_ON_SELECTED_OVER_UNDER:
      return setPowerUpOnSelectedOverUnder(state, action.payload);
    default:
      return state;
  }
};
