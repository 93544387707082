import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';

import { getFeatureFlagFromLocalStorage } from '@/store/modules/feature-flags/utils';

import { auth0Service } from '@/components/contexts/auth/auth0-service';
import eventLogger from '@/utilities/analytics';
import {
  AUTH0_SESSION_REFRESH,
  MOBILE_UD_CLIENT_TYPE,
  MOBILE_UD_CLIENT_VERSION,
  MOBILE_UD_REF,
  UD_DEVICE_ID,
  UD_REF,
} from '@/utilities/constants';
import { APIError } from '@/utilities/errors';
import errorLogger from '@/utilities/errors/logger';
// eslint-disable-next-line import/no-cycle
import { getLocationHeaders } from '@/utilities/location';
import geoService from '@/utilities/location/geocomply';

export const clientType = Cookies.get(MOBILE_UD_CLIENT_TYPE) || 'web';
export const clientVersion =
  Cookies.get(MOBILE_UD_CLIENT_VERSION) || process.env.CLIENT_VERSION || 1;

const requestHeaders: { [key: string]: any } = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'Client-Type': clientType,
  'Client-Version': clientVersion,
};

const APP_ENV = process.env.APP_ENV || 'development';

if (APP_ENV === 'local') {
  // To fix cors errors
  requestHeaders['X-Forwarded-For'] = '71.105.237.107';
}

if (APP_ENV === 'local' || APP_ENV === 'development') {
  window.Cookies = Cookies;
}

export const API_ENDPOINTS = {
  api: process.env.API_ENDPOINT || 'http://localhost:3000/api',
  stats: process.env.STAT_ENDPOINT || 'http://localhost:3000/stats',
};

export const getAuth = async (): Promise<string> => {
  // devise token
  const deviseAccessToken = Cookies.get('session');
  if (deviseAccessToken) {
    return deviseAccessToken;
  }

  // get auth from auth0service
  const accessToken = await auth0Service.getAuth();
  return accessToken;
};

export const removeAuth = () => {
  Cookies.remove('session');
  auth0Service.removeRefreshToken();
  eventLogger({
    gtm: {
      eventName: 'log_out',
    },
  });
};

export const getReferral = () => Cookies.get(MOBILE_UD_REF) || Cookies.get(UD_REF) || null;

export const removeReferral = () => {
  Cookies.remove(MOBILE_UD_REF);
  Cookies.remove(UD_REF);
};

// update this with server/request
interface RequestConfig extends AxiosRequestConfig {
  underdogAPIVersion?:
    | 'beta/v1'
    | 'beta/v2'
    | 'beta/v3'
    | 'beta/v4'
    | 'beta/v5'
    | 'v1'
    | 'v2'
    | 'v3'
    | 'v4'
    | 'v5'
    | 'v6';
  underdogAPIEndpoint?: keyof typeof API_ENDPOINTS;
  requiresGeoComply?: boolean;
  requiresLatLong?: boolean;
}

export const getDeviceId = () => {
  let deviceId = Cookies.get(UD_DEVICE_ID);

  if (!deviceId) {
    deviceId = uuidv4();
    Cookies.set(UD_DEVICE_ID, deviceId, { expires: 180 });
  }

  return deviceId;
};

export type RequestService = typeof request;

const request = async (options: RequestConfig): Promise<AxiosResponse> => {
  const authToken = await getAuth();
  const referral = getReferral();
  const deviceId = getDeviceId();
  let locationHeaders = {};

  const { lat, long } = getLocationHeaders({
    required: options.requiresLatLong || options.requiresGeoComply,
  });
  locationHeaders = {
    'User-Latitude': lat,
    'User-Longitude': long,
  };

  const isGeoComplyFeatureEnabled = getFeatureFlagFromLocalStorage('geoComply');

  if (options.requiresLatLong) {
    const token = geoService.getExistingTokenOrNull();
    if (token) {
      requestHeaders['User-Location-Token'] = token || '';
    }
  } else {
    requestHeaders['User-Location-Token'] = '';
  }

  if (options.requiresGeoComply) {
    if (isGeoComplyFeatureEnabled) {
      const token = await geoService.getToken();
      if (token) {
        requestHeaders['User-Location-Token'] = token;
      }
    }
  } else {
    requestHeaders['User-Location-Token'] = '';
  }

  try {
    const response = await axios.request({
      timeout: 60000,
      ...options,
      headers: {
        ...requestHeaders,
        ...locationHeaders,
        ...options.headers,
        'Referring-Link': referral || '',
        'Client-Device-Id': deviceId,
        'Client-Request-Id': uuidv4(),
        Authorization: authToken || '',
      },
      url: `${API_ENDPOINTS[options.underdogAPIEndpoint || 'api']}/${
        options.underdogAPIVersion || 'v1'
      }${options.url}`,
      params: {
        ...options.params,
      },
      paramsSerializer: {
        ...options.paramsSerializer,
      },
    });

    // update the devise auth cookie with every successful web response
    if (
      response.headers.authorization &&
      clientType === 'web' &&
      !Cookies.get(AUTH0_SESSION_REFRESH)
    ) {
      Cookies.set('session', response.headers.authorization);
    }

    geoService.setLocationExpiry(response.headers);

    return response;
  } catch (err) {
    if (!err?.response?.data?.error) {
      errorLogger(true, err);
      throw err;
    }
    errorLogger(true, err, { url: err?.config?.url, source: 'request' });
    throw new APIError(err.response.data.error);
  }
};

export default request;
