/* eslint-disable camelcase */
import airdropAdapter from '@/store/modules/airdrops/adapters';
import appearanceAdapter from '@/store/modules/constructed-slates/adapters/appearance';
import playerAdapter from '@/store/modules/constructed-slates/adapters/player';
import matchAdapter from '@/store/modules/matches/adapters/match';
import powerUpAdapter from '@/store/modules/power-ups/adapters';

import {
  FeaturedLobby,
  FeaturedLobbyResponse,
  PlayerGroupValue,
  RivalLinesResponse,
} from '@/interfaces/pick-em';
import { arrayToObjectIdKeys } from '@/utilities/helpers';

import overUnderLineAdapter from './over-under-lines';
import pickEmPackAdapter from './pack';
import rivalLineAdapter from './rival-line';
import soloGameAdapter from './solo-game';

const featuredLobbyAdapter = (
  featuredLobby: FeaturedLobbyResponse,
  rivalLines: RivalLinesResponse['rival_lines']
): FeaturedLobby => {
  const {
    airdrop_offers,
    appearances,
    games,
    over_under_lines,
    pickem_packs,
    player_groups,
    players,
    power_ups = [],
    rival_lines,
    sections,
    solo_games,
  } = featuredLobby;

  const adaptedSections = sections.map((section) => ({
    title: section.title,
    contentType: section.content_type,
    key: section.key,
    sportId: section.sport_id,
    style: section.style,
  }));

  const playerGroupsKeys = Object.keys(player_groups);
  const adaptedPlayerGroups: { [group: string]: PlayerGroupValue[] } = {};
  playerGroupsKeys.forEach((group) => {
    adaptedPlayerGroups[group] = player_groups[group].map((prop) => ({
      id: prop.id,
      appearanceIds: prop.appearance_ids,
    }));
  });

  const adaptedAirdrops = airdrop_offers.map(airdropAdapter);
  const adaptedAppearances = appearances.map(appearanceAdapter);
  const adaptedGames = games.map(matchAdapter);
  const adaptedOverUnderLines = over_under_lines.map(overUnderLineAdapter);
  const adaptedRivalLines = rival_lines.map(rivalLineAdapter);
  const adaptedPickemPacks = pickem_packs.map(pickEmPackAdapter);
  const adaptedPlayers = arrayToObjectIdKeys(players.map(playerAdapter));
  const adaptedPowerUps = power_ups.map(powerUpAdapter);
  const adaptedSoloGames = solo_games.map(soloGameAdapter);

  // ranked by # of overUnderLines per appearance
  const adaptedFavorites = adaptedPlayerGroups.favorites?.map((group) => {
    if (!group.appearanceIds.length) {
      return {
        ...group,
        numberOfActiveLines: 0,
        adaptedPlayer: adaptedPlayers[group.id],
      };
    }

    const activeOverUnderLines = adaptedOverUnderLines.filter((oUL) =>
      group.appearanceIds.includes(oUL.overUnder?.appearanceStat.appearanceId)
    );

    const numberOfSplitLines = activeOverUnderLines.filter(
      (line) =>
        line.options.some((option) => option.payoutMultiplier > 1) && line.options.length > 1
    ).length;

    const numberOfActiveOverUnderLines = activeOverUnderLines.length + numberOfSplitLines;

    const numberOfActiveRivalLines = rivalLines.filter(
      (rivalLine) =>
        group.appearanceIds.includes(rivalLine.options[0].appearance_stat.appearance_id) ||
        group.appearanceIds.includes(rivalLine.options[1].appearance_stat.appearance_id)
    )?.length;

    const numberOfActiveLines = numberOfActiveOverUnderLines + numberOfActiveRivalLines;

    return {
      ...group,
      numberOfActiveLines,
      adaptedPlayer: adaptedPlayers[group.id],
    };
  });

  const activeFavorites =
    adaptedFavorites
      ?.filter((fav) => fav.numberOfActiveLines > 0)
      ?.sort((a, b) => b.numberOfActiveLines - a.numberOfActiveLines) ?? [];

  const inactiveFavorites =
    adaptedFavorites
      ?.filter((fav) => fav.numberOfActiveLines === 0)
      ?.sort((a, b) => {
        const ruleA = a.adaptedPlayer.lastName?.toLowerCase();
        const ruleB = b.adaptedPlayer.lastName?.toLowerCase();
        if (ruleA < ruleB) return -1;
        if (ruleB < ruleA) return 1;
        return 0;
      }) ?? [];

  return {
    airdrops: adaptedAirdrops,
    appearances: adaptedAppearances,
    // constructedAppearances and constructedRivalLines will be set in reducer
    constructedAppearances: {},
    constructedPromoAppearances: [],
    constructedRivalLines: [],
    favoritePlayers: [...activeFavorites, ...inactiveFavorites],
    games: adaptedGames,
    hasFeaturedLobbyLoaded: true,
    overUnderLines: adaptedOverUnderLines,
    pickemPacks: adaptedPickemPacks,
    playerGroups: adaptedPlayerGroups,
    players: adaptedPlayers,
    powerUps: adaptedPowerUps,
    rivalLines: adaptedRivalLines,
    sections: adaptedSections,
    soloGames: adaptedSoloGames,
  };
};

export default featuredLobbyAdapter;
