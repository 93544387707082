import { datadogLogs } from '@datadog/browser-logs';
import { AxiosResponseHeaders } from 'axios';
import Cookies from 'js-cookie';

import {
  geoComplyLicenseAdapter,
  geoComplyLocationAdapter,
} from '@/store/modules/geocomply/adapters/geocomply';

// eslint-disable-next-line import/no-cycle
import { checkLocation as checkLocationApi, getLicense as getLicenseApi } from '@/api/geocomply';
import { UD_LOCATION_STATE, UD_LOCATION_TOKEN } from '@/utilities/constants';
import { AppError } from '@/utilities/errors';
import errorLogger from '@/utilities/errors/logger';

class GeoComplyService {
  private geoClient: any;

  private geoComplyLicenseExpiry: number; // milliseconds

  private geoComplyTokenExpiry: number; // milliseconds

  private timeout: NodeJS.Timeout;

  token: string;

  timeBeforeRequest: number;

  initialized: boolean;

  ipChangeDetected: boolean;

  constructor() {
    this.geoClient = window.GcHtml5?.createClient();
    this.geoComplyTokenExpiry = null;
    if (Cookies.get(UD_LOCATION_TOKEN)) {
      this.token = Cookies.get(UD_LOCATION_TOKEN);
    }
    this.initialized = false;
    this.ipChangeDetected = false;

    this.onFocusChangeDetected = this.onFocusChangeDetected.bind(this);
    document.addEventListener('visibilitychange', this.onFocusChangeDetected);
  }

  async init({ userId }: { userId: string }) {
    // GcHtml
    if (!this.geoClient) {
      this.geoClient = window.GcHtml5.createClient();
    }
    if (!userId) {
      return errorLogger(true, 'GeoService init error: no userId');
    }

    // Set parameters and license string
    this.geoClient.setUserId(userId);
    await this.setGCLicense();

    window.GcHtml5.onMyIpSuccess = () => {
      window.GcHtml5.ackMyIPSuccess(); // acknowledge to stop loop notification of ip change
      if (!this.ipChangeDetected) {
        this.ipChangeDetected = true;
        this.makeRequest();
      }
    };

    window.GcHtml5.onMyIpFailure = (code: string, message: string) => {
      throw new AppError({
        title: 'Failed IP change detection',
        apiCode: code,
        message,
      });
    };

    // Request Geolocation
    this.makeRequest();

    return new Promise((resolve, reject) => {
      this.geoClient.events
        .on('*.failed', (message: string) => {
          reject(message);
        })
        .on('engine.success', async (token: string) => {
          const timeAfterRequest = new Date().getTime();
          datadogLogs.logger.info(
            // log the time to return request success
            'GeoComply request',
            { time: timeAfterRequest - this.timeBeforeRequest }
          );
          this.initialized = true;
          this.token = token;
          await this.verifyLocation();

          resolve(token);
        });
    });
  }

  private onFocusChangeDetected() {
    if (this.ipChangeDetected) {
      this.makeRequest();
    }
  }

  private makeRequest() {
    // to prevent requests on multiple tabs
    if (!document.hidden) {
      let reasonCode: string;

      if (this.ipChangeDetected) {
        reasonCode = this.geoClient.REASON_CODE.IPCHANGE;
      } else {
        // If the token hasn't been set we can assume this is a login
        // otherwise it is because the token has expired.
        reasonCode = this.token
          ? this.geoClient.REASON_CODE.INTERVAL
          : this.geoClient.REASON_CODE.LOGIN;
      }

      this.ipChangeDetected = false;
      this.geoClient.setReasonCode(reasonCode);
      this.geoClient.abort();
      this.timeBeforeRequest = new Date().getTime();
      this.geoClient.request();
    }
  }

  private async setGCLicense() {
    const { data } = await getLicenseApi();
    const adaptedGCLicense = geoComplyLicenseAdapter(data.data);

    const { license, expiresAt } = adaptedGCLicense;

    this.geoClient.setLicense(license);
    this.geoComplyLicenseExpiry = new Date(expiresAt).getTime(); // milliseconds
    window.GcHtml5.startMyIpService({
      license,
      resumable: true,
    });

    // Get license again after expiration
    this.refreshLicenseLocation();
  }

  private async refreshLicenseLocation() {
    const now = new Date().getTime(); // now in milliseconds
    const licenseExpiryInMilliseconds = Math.max(this.geoComplyLicenseExpiry - now, 0);

    setTimeout(() => {
      this.setGCLicense();
    }, licenseExpiryInMilliseconds);
  }

  private async verifyLocation() {
    if (this.token) {
      const { data } = await checkLocationApi(this.token);
      const adaptedGCLocation = geoComplyLocationAdapter(data);

      const { isCompliant, expiresAt, state } = adaptedGCLocation;
      sessionStorage.setItem(UD_LOCATION_STATE, state);
      this.geoComplyTokenExpiry = new Date(expiresAt).getTime();
      this.recheckLocation(isCompliant);
    }
  }

  private recheckLocation(isCompliant: boolean) {
    const now = new Date().getTime();
    const locationExpiryInMilliseconds = isCompliant
      ? Math.max(this.geoComplyTokenExpiry - now, 0)
      : 300000; // check for valid location every 5 min

    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.makeRequest();
    }, locationExpiryInMilliseconds);
  }

  getExistingTokenOrNull() {
    // only use this for when the location requirement is lat/long as it won't initialize geocomply
    if (Cookies.get(UD_LOCATION_TOKEN)) {
      return Cookies.get(UD_LOCATION_TOKEN);
    }
    const now = new Date().getTime();
    if (this.token && now < this.geoComplyTokenExpiry) {
      return this.token;
    }

    return null;
  }

  async getToken() {
    if (Cookies.get(UD_LOCATION_TOKEN)) {
      return Cookies.get(UD_LOCATION_TOKEN);
    }
    if (!this.initialized) {
      // if we haven't initialized geoComply, then just return null
      return null;
    }
    return new Promise((resolve, reject) => {
      const now = new Date().getTime();
      if (this.token && now < this.geoComplyTokenExpiry) {
        resolve(this.token);
        return;
      }
      this.geoClient.events
        .on('*.failed', (message: string) => {
          reject(message);
        })
        .on('engine.success', async (text: string) => {
          this.token = text;
          await this.verifyLocation();
          resolve(text);
        });
      this.makeRequest();
    });
  }

  setLocationExpiry(headers: Partial<AxiosResponseHeaders>) {
    if (headers['location-expires']) {
      this.geoComplyTokenExpiry = new Date(headers['location-expires']).getTime();
    }
  }

  endUserSession() {
    document.removeEventListener('visibilitychange', this.onFocusChangeDetected);
    window.GcHtml5.stopMyIpService();
    return this.geoClient.invalidateUserSession();
  }
}

export default new GeoComplyService();
