import React from 'react';
import { connect } from 'react-redux';

import { RootState } from '@/store';

import BoostIcon from '@/components/atoms/boost-icon';
import Icon from '@/components/atoms/icon';
import { ScoringSection } from '@/components/atoms/info-modal-components';
import { HandleCloseModal, useModal } from '@/components/atoms/modal';
import DialogModal from '@/components/molecules/dialog-modal';
import { ScoringTypes } from '@/interfaces/drafting-config';
import { OverUnderLine } from '@/interfaces/pick-em';

import styles from './styles.scss';

export interface InfoButtonsProps {
  overUnderLine: OverUnderLine;
  scoringTypes: ScoringTypes;
}

const InfoButtons = (props: InfoButtonsProps) => {
  const { overUnderLine, scoringTypes } = props;

  const { boost } = overUnderLine.overUnder;

  const openModal = useModal();

  const handleScoringModalClick = (oUL: OverUnderLine) => {
    const scoringType = scoringTypes?.[oUL.overUnder.scoringTypeId];
    if (scoringType) {
      openModal(({ handleCloseModal }: HandleCloseModal) => (
        <DialogModal
          handleCloseModal={handleCloseModal}
          title="Scoring"
          content={<ScoringSection scoringType={scoringType} displayTitle={false} />}
        />
      ));
    }
  };

  const boostIconEl = boost ? <BoostIcon boost={boost} /> : null;

  return (
    <>
      {overUnderLine.overUnder?.scoringTypeId && (
        <button
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation();
            handleScoringModalClick(overUnderLine);
          }}
          aria-label="Scoring info"
          className={styles.scoringInfoButton}
        >
          <Icon name="info" className={styles.scoringModalIcon} />
        </button>
      )}
      {boostIconEl}
    </>
  );
};

export default connect((state: RootState) => ({
  scoringTypes: state.draftingConfig.scoringTypes,
}))(InfoButtons);
