import React from 'react';
import clsx from 'clsx';

import Icon, { IconTypes } from '@/components/atoms/icon';
import { useThemeContext } from '@/components/contexts/theme';
import { ContentfulPromoInfoUser } from '@/interfaces/contentful-promo-info';
import { User as DraftUser } from '@/interfaces/drafts';
import { ContentfulNewsItemUser } from '@/interfaces/news';
import { User } from '@/interfaces/user';

import styles from './styles.scss';

interface AvatarProps {
  user?: User | DraftUser | ContentfulPromoInfoUser | ContentfulNewsItemUser;
  customPlaceholder?: IconTypes;
  classNames: {
    badge?: string;
    wrapper?: string;
    avatar?: string;
  };
  url?: string;
  iconEl?: JSX.Element;
  backgroundColor?: string;
}

const Avatar = (props: AvatarProps) => {
  const { classNames, customPlaceholder, user, url, iconEl, backgroundColor } = props;
  const { theme } = useThemeContext();

  const wrapperClasses = clsx(styles.avatarWrapper, classNames.wrapper);

  let badgeEl: React.ReactNode = null;
  if (user && 'badges' in user) {
    const userBadges = user.badges;

    if (Array.isArray(userBadges) && userBadges.length > 0) {
      const badgeUrl = theme === 'light' ? userBadges[0].lightModeUrl : userBadges[0].darkModeUrl;

      badgeEl = (
        <div
          title={userBadges[0].title}
          className={clsx(styles.badge, classNames.badge)}
          style={{ backgroundImage: `url(${badgeUrl})` }}
        />
      );
    }
  }

  const onImgError = (err: any) => {
    const fallBackImg = 'https://assets.underdogfantasy.com/profile-icons/fallback.png';
    // if fallBackImg is also broken, don't go into an infinite error loop
    if (err.target.currentSrc !== fallBackImg) {
      err.target.src = fallBackImg; // eslint-disable-line no-param-reassign
    }
  };

  if (url) {
    return (
      <div className={wrapperClasses}>
        <div className={clsx(styles.avatar, classNames.avatar)}>
          <div
            data-testid="avatar-url"
            className={styles.imageContainer}
            style={{
              backgroundImage: `url(${url})`,
              backgroundColor,
            }}
          />
        </div>
        {iconEl}
      </div>
    );
  }

  if (user && user.profileIconUrl) {
    return (
      <div className={wrapperClasses}>
        {badgeEl}
        <div className={clsx(styles.avatar, classNames.avatar)}>
          <img
            data-testid="avatar-user"
            className={styles.imageContainer}
            src={user.profileIconUrl}
            alt="userAvatar"
            onError={onImgError}
            style={{ backgroundColor: user.profileColor }}
          />
        </div>
        {iconEl}
      </div>
    );
  }

  return (
    <div className={wrapperClasses}>
      <div className={clsx(styles.avatar, classNames.avatar)}>
        <Icon name={customPlaceholder || 'logoPlaceholder'} className={styles.logoPlaceholder} />
      </div>
      {iconEl}
    </div>
  ); // we'll return the avatar for a player here later
};

export default Avatar;
