/* eslint-disable camelcase */
import powerUpAdapter from '@/store/modules/power-ups/adapters/index';
import { badgeAdapter } from '@/store/modules/user/adapters/user';

import { EntrySlip, EntrySlipResponse } from '@/interfaces/pick-em';

import insuranceFallbackAdapter from './insurance-fallback';
import selectionAdapter from './selection';

const entrySlipAdapter = (entrySlip: EntrySlipResponse): EntrySlip => {
  const {
    id,
    badges: badgesResponse,
    boost_id,
    boost_payout,
    cancellation_expires_at,
    champion_points,
    created_at,
    current_max_payout_multiplier,
    end_at,
    entry_count,
    entry_style_id,
    fee,
    free_entry,
    image_url,
    initial_max_payout_multiplier,
    insurance_fallbacks: insuranceFallbacksResponse,
    insured,
    payout,
    payout_at,
    payout_option_id,
    payout_shifts,
    pickem_pool_entry,
    pickem_pool_style_id,
    pickem_pool_style_option_id,
    power_up,
    prize_max_payout,
    rebooted,
    selections: selectionsResponse,
    settled_pickem_pool_style_option_id,
    share_id,
    share_link,
    start_at,
    status,
    title,
    user,
    username,
  } = entrySlip;

  const badges = badgesResponse?.map(badgeAdapter);
  const insuranceFallbacks = insuranceFallbacksResponse?.map(insuranceFallbackAdapter);
  const selections = selectionsResponse?.map(selectionAdapter);

  let adaptedPickEmPoolEntry = null;
  if (pickem_pool_entry) {
    adaptedPickEmPoolEntry = {
      id: pickem_pool_entry.id,
      payout: pickem_pool_entry.payout,
      pickemPoolId: pickem_pool_entry.pickem_pool_id,
      poolEntryCount: pickem_pool_entry.pool_entry_count,
    };
  }

  let adaptedUser = null;
  if (user) {
    adaptedUser = {
      id: entrySlip.user.id,
      profileColor: entrySlip.user.profile_color,
      profileIconUrl: entrySlip.user.profile_icon_url,
      username: entrySlip.user.username,
      badges: entrySlip.user.badges.map(badgeAdapter),
    };
  }

  let adaptedPowerUp = null;
  if (power_up) {
    adaptedPowerUp = powerUpAdapter(power_up);
  }

  return {
    id,
    badges,
    boostId: boost_id,
    boostPayout: boost_payout,
    cancellationExpiresAt: cancellation_expires_at,
    championPoints: champion_points,
    createdAt: created_at,
    currentMaxPayoutMultiplier: current_max_payout_multiplier,
    endAt: end_at,
    entryCount: entry_count,
    entryStyleId: entry_style_id,
    fee,
    freeEntry: free_entry,
    imageUrl: image_url,
    initialMaxPayoutMultiplier: initial_max_payout_multiplier,
    insuranceFallbacks,
    insured,
    payout,
    payoutAt: payout_at,
    payoutOptionId: payout_option_id,
    payoutShifts: payout_shifts,
    pickemPoolEntry: adaptedPickEmPoolEntry,
    pickemPoolStyleId: pickem_pool_style_id,
    pickemPoolStyleOptionId: pickem_pool_style_option_id,
    powerUp: adaptedPowerUp,
    prizeMaxPayout: prize_max_payout,
    rebooted,
    selections,
    settledPickemPoolStyleOptionId: settled_pickem_pool_style_option_id,
    shareId: share_id,
    shareLink: share_link,
    startAt: start_at,
    status,
    title,
    user: adaptedUser,
    username,
  };
};

export default entrySlipAdapter;
