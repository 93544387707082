import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import clsx from 'clsx';

import { RootState } from '@/store';

import { HandleCloseModal, useModal } from '@/components/atoms/modal';
import TextSlideUpAnimation from '@/components/molecules/animations/text-slide-up';
import DialogModal from '@/components/molecules/dialog-modal';
import OptionsWrapper from '@/components/molecules/options-wrapper';
import InGameInfo from '@/components/pages/pick-em/components/in-game-info';
import { doesTextContainSearchTerm } from '@/components/pages/pick-em/components/over-under-section/helpers';
import {
  AppearanceLine,
  ConstructedPickEmOverUnderLineAppearance,
  SelectedOverUnder,
} from '@/interfaces/constructed-interfaces/constructed-pick-em-over-under-appearance';
import { PickLocation } from '@/interfaces/pick-em';
import { RedeemablePowerUp } from '@/interfaces/power-ups';
import { POWER_UP_SPECIAL_STAT_VALUE } from '@/utilities/constants';
import langHelper from '@/utilities/lang-helper';

import InGameProjection from './components/in-game-projection';
import InfoButtons from './components/info-buttons';
import StatChangeIcon from './components/stat-change-icon';

import styles from './styles.scss';

export interface OverUnderListCellProps {
  appearanceLine: AppearanceLine;
  constructedPickEmAppearance: ConstructedPickEmOverUnderLineAppearance;
  normalizedSearchTerm: string;
  pickLocation: PickLocation;
  powerUp: RedeemablePowerUp;
  selectedOverUnder: SelectedOverUnder;
}

const OverUnderListCell = (props: OverUnderListCellProps) => {
  const {
    appearanceLine,
    constructedPickEmAppearance,
    normalizedSearchTerm,
    pickLocation,
    powerUp,
    selectedOverUnder,
  } = props;

  const overUnderLine = useSelector((state: RootState) => {
    return state.pickEmOverUnder.overUnderLines[appearanceLine.id];
  });

  const openModal = useModal();

  const [swapStatValueAndAnimate, setSwapStatValueAndAnimate] = useState<boolean>(false);
  const [swapLineOptionAndAnimate, setSwapLineOptionAndAnimate] = useState<boolean>(false);

  const optionChoice: 'higher' | 'lower' | null = appearanceLine.option;

  const isSuspended = overUnderLine?.status === 'suspended';
  const isRemoved = overUnderLine?.status === 'removed';

  const onlyMultiplierSwap =
    overUnderLine?.oldStatValue === overUnderLine?.statValue && overUnderLine?.oldLineOptions;

  useEffect(() => {
    if (overUnderLine?.oldOverUnderLineId && !onlyMultiplierSwap) {
      setSwapStatValueAndAnimate(true);
    }
    if (overUnderLine?.oldLineOptions) {
      setSwapLineOptionAndAnimate(true);
    }
  }, [onlyMultiplierSwap, overUnderLine]);

  if (!overUnderLine) {
    return null;
  }

  /**
   * If we're searching and the keyword matches the player/team name, we want to show all the
   * lines for that player, regardless of whether the keyword matches the appearance stat.
   * However, if the keyword doesn't match the player/team name, we only want to show the lines
   * where the keyword matches the appearance stat.
   * PS: We only want to show the search results in the lobby.
   */
  const playerFullName = langHelper.getPlayerFullName(constructedPickEmAppearance.player);
  const playerTeam = constructedPickEmAppearance?.team
    ? `${constructedPickEmAppearance.team.name} ${constructedPickEmAppearance.team.abbr}`
    : '';

  const keywordMatchesPlayerName =
    doesTextContainSearchTerm(playerFullName, normalizedSearchTerm) ||
    (playerTeam && doesTextContainSearchTerm(playerTeam, normalizedSearchTerm));

  if (
    pickLocation === PickLocation.LOBBY &&
    normalizedSearchTerm &&
    !keywordMatchesPlayerName &&
    !doesTextContainSearchTerm(
      overUnderLine.overUnder.appearanceStat.displayStat.toLocaleLowerCase(),
      normalizedSearchTerm
    )
  ) {
    return null;
  }

  const keyboardSelect = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      openIGPInfoModal();
    }
  };

  const openIGPInfoModal = () => {
    openModal(({ handleCloseModal }: HandleCloseModal) => (
      <DialogModal
        handleCloseModal={handleCloseModal}
        title="In-game projections"
        content={<InGameInfo />}
      />
    ));
  };

  const updatedStatAndIconEl = (
    <p className={styles.updatedStat}>
      <div className={styles.statValue}>
        {overUnderLine.statValue} <InfoButtons overUnderLine={overUnderLine} />{' '}
        {overUnderLine.liveEvent && <InGameProjection overUnderLine={overUnderLine} />}
      </div>
      <div className={styles.displayStat}>
        {overUnderLine?.overUnder?.appearanceStat.displayStat}
      </div>
    </p>
  );

  const showSpecialStatChangeUI =
    selectedOverUnder?.powerUpId === powerUp?.id && powerUp?.type === 'special';

  const currentStatEl = (
    <div className={styles.currentStat}>
      <div className={styles.statValue}>
        <span
          className={clsx({
            [styles.specialStatChange]: showSpecialStatChangeUI,
          })}
        >
          {overUnderLine.oldStatValue || overUnderLine.statValue}{' '}
        </span>
        {showSpecialStatChangeUI ? (
          <>
            <span>{POWER_UP_SPECIAL_STAT_VALUE}</span>{' '}
          </>
        ) : null}
        <InfoButtons overUnderLine={overUnderLine} />{' '}
        {overUnderLine.liveEvent && <InGameProjection overUnderLine={overUnderLine} />}
      </div>
      <div className={styles.displayStat}>
        {overUnderLine?.overUnder?.appearanceStat.displayStat}
      </div>
    </div>
  );

  return (
    <div
      key={overUnderLine.id}
      className={clsx({
        [styles.removed]: isRemoved,
        [styles.overUnderListCell]: pickLocation !== PickLocation.PLAYER_CARD,
        [styles.playerModal]: pickLocation === PickLocation.PLAYER_CARD,
      })}
      data-rank={overUnderLine.rank}
      data-over-under-line-id={overUnderLine.id}
      data-testid="over-under-list-cell"
    >
      <div
        className={styles.statLineColumn}
        data-testid="stat-line-container"
        onClick={
          overUnderLine.liveEvent
            ? (e: React.MouseEvent<HTMLDivElement>) => {
                e.stopPropagation();
                openIGPInfoModal();
              }
            : null
        }
        role="button"
        tabIndex={0}
        onKeyDown={(e) => {
          e.stopPropagation();
          keyboardSelect(e);
        }}
      >
        <div className={styles.topHalf} data-testid="stat-line">
          <div
            className={clsx(styles.statLine, {
              [styles.suspendedStat]: isSuspended,
            })}
          >
            <TextSlideUpAnimation
              defaultContent={currentStatEl}
              incomingContent={updatedStatAndIconEl}
              swapCondition={swapStatValueAndAnimate}
            />
            <StatChangeIcon overUnderLine={overUnderLine} />
          </div>
        </div>
      </div>

      <OptionsWrapper
        pickLocation={pickLocation}
        constructedAppearance={constructedPickEmAppearance}
        overUnderLine={overUnderLine}
        selectedOptionId={selectedOverUnder?.option.id}
        swapLineOptionAndAnimate={swapLineOptionAndAnimate}
        swapStatValueAndAnimate={swapStatValueAndAnimate}
        optionChoice={optionChoice}
        classNames={{
          wrapper: styles.overUnderOptionsWrapper,
        }}
      />
    </div>
  );
};

export default connect((state: RootState) => ({
  normalizedSearchTerm: state.pickEmOverUnder.normalizedSearchTerm,
}))(OverUnderListCell);
