import React from 'react';

import Nav from '@/components/molecules/nav';
import Footer from '@/components/organisms/footer';

import styles from './styles.scss';

interface FullPageLayoutProps {
  children: React.ReactNode;
  hasNav?: boolean;
  hasFooter?: boolean;
  layout: 'wide' | 'narrow';
}

const FullPageLayout = ({ children, hasNav, hasFooter, layout }: FullPageLayoutProps) => (
  <>
    {hasNav && <Nav />}
    <div className={styles.fullPage}>
      <div className={styles[layout]}>{children}</div>
    </div>
    {hasFooter && <Footer />}
  </>
);
export default FullPageLayout;
