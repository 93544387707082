import React, { useContext } from 'react';
import { Link, LinkProps } from 'react-router-dom';

import { NavigationContext } from '@/components/contexts/navigation';

export interface LinkWrapperProps extends LinkProps {
  testId?: string;
}

const LinkWrapper = (props: LinkWrapperProps) => {
  const { children, className, onClick, target, testId, to } = props;
  const { getNextRoute } = useContext(NavigationContext);

  return (
    <Link
      className={className}
      onClick={onClick}
      target={target}
      to={getNextRoute({ path: to })}
      data-testid={testId}
    >
      {children}
    </Link>
  );
};

export default LinkWrapper;
