import {
  bestBallCopy as bestBallCopyFallback,
  bestBallTitle as bestBallTitleFallback,
  draftCanadaNote as draftCanadaNoteFallback,
  draftStates as draftStatesFallback,
  pickEmCanadaNote as pickEmCanadaNoteFallback,
  pickEmStates as pickEmStatesFallback,
} from '@/components/pages/landing/landing-page-data';
import { LandingPageConfigs, LandingPageConfigsResponse } from '@/interfaces/landing-page-configs';

const landingPageConfigsAdapter = (data: LandingPageConfigsResponse): LandingPageConfigs => {
  if (data?.landingPageConfigs?.items?.length > 0) {
    const config = data.landingPageConfigs.items[0];
    const {
      bestBallCopy,
      bestBallTitle,
      draftStates,
      draftCanadaNote,
      pickEmStates,
      pickEmCanadaNote,
    } = config?.fields?.stateConfigs ?? {};
    return {
      id: config?.sys?.id,
      landingPageConfigs: {
        bestBallCopy,
        bestBallTitle,
        draftStates,
        draftCanadaNote,
        pickEmStates,
        pickEmCanadaNote,
      },
    };
  }

  // Return fallback values in the case that the Contentful API call errors out
  return {
    id: 'landing_page_fallback',
    landingPageConfigs: {
      bestBallCopy: bestBallCopyFallback,
      bestBallTitle: bestBallTitleFallback,
      draftStates: draftStatesFallback,
      draftCanadaNote: draftCanadaNoteFallback,
      pickEmStates: pickEmStatesFallback,
      pickEmCanadaNote: pickEmCanadaNoteFallback,
    },
  };
};

export default landingPageConfigsAdapter;
