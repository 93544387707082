import { ampli } from '@/ampli';
import { getDeviceId } from '@/api/request';
import { RegistrationSource } from '@/interfaces/user';

import { formatRegistrationSource } from './helpers';

const environment = process.env.APP_ENV === 'production' ? 'production' : 'development';

export const loadAmplitude = () => {
  ampli.load({
    client: {
      configuration: {
        defaultTracking: {
          sessions: true,
        },
      },
    },
    environment,
  });
};

export const identifyAmplitudeUser = ({
  userId,
  registrationSource,
}: {
  userId: string;
  registrationSource: RegistrationSource;
}) => {
  const formattedRegistrationSource = formatRegistrationSource(registrationSource);

  ampli.identify(userId, formattedRegistrationSource, {
    device_id: getDeviceId(),
    user_id: userId,
  });
};

export const batchAmplitudeEvents = () => {
  ampli.client.flush();
};

export default ampli;
