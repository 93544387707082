import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '@/store';

import { FeatureFlags } from '@/interfaces/feature-flags';
import { User } from '@/interfaces/user';

import { getIsPoolsEnabled } from '../helpers';

const useIsPoolsEnabled = () => {
  const featureFlags = useSelector<RootState, FeatureFlags>((state) => state.featureFlags);
  const user = useSelector<RootState, User>((state) => state.user);

  const isPoolsEnabled = useMemo(() => {
    const {
      forceClassicPickemVisible: isForceClassicPickemVisibleFeatureFlagEnabled,
      forcePickemPoolsVisible: isForcePickemPoolsVisibleFeatureFlagEnabled,
    } = featureFlags;

    return getIsPoolsEnabled({
      user,
      isForceClassicPickemVisibleFeatureFlagEnabled,
      isForcePickemPoolsVisibleFeatureFlagEnabled,
    });
  }, [featureFlags, user]);

  return isPoolsEnabled;
};

export default useIsPoolsEnabled;
