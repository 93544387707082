import React, { useContext } from 'react';
import clsx from 'clsx';

import Avatar from '@/components/atoms/avatar';
import Icon from '@/components/atoms/icon';
import { ThemeContext } from '@/components/contexts/theme';
import { Player } from '@/interfaces/constructed-interfaces/constructed-slates';
import { Sport, Team } from '@/interfaces/drafting-config';
import { Boost } from '@/interfaces/pick-em';
import { AppliedPowerUp, RedeemablePowerUp } from '@/interfaces/power-ups';
import useTeamColors from '@/utilities/hooks/use-team-colors';

import styles from './styles.scss';

// boost always supersedes scorcher, powerUp supersedes boost
export type AvatarBorderType =
  | 'boost'
  | 'none'
  | 'powerUp'
  | 'primary'
  | 'scorcher'
  | 'transparent';

export interface PlayerDetailedAvatarProps {
  backgroundStripes?: boolean;
  borderType: AvatarBorderType;
  boost?: Boost;
  circleStripes?: boolean;
  classNames?: {
    avatar?: string;
  };
  iconText?: string;
  player: Player;
  powerUp?: AppliedPowerUp | RedeemablePowerUp;
  size: 'small' | 'regular' | 'large';
  sport: Sport;
  team: Team;
}

const PlayerDetailedAvatar = (props: PlayerDetailedAvatarProps) => {
  const {
    backgroundStripes,
    circleStripes,
    classNames,
    borderType,
    boost,
    iconText,
    player,
    powerUp,
    size,
    sport,
    team,
  } = props;
  const { theme } = useContext(ThemeContext);
  let [primaryColor, secondaryColor] = useTeamColors({ team, sport });
  let boostBorder: string = '';

  if (boost) {
    primaryColor = boost.color;
    secondaryColor = `${boost.color}7B`; // use 50% opacity
    const avatarBackgroundColor = theme === 'dark' && !circleStripes ? '#1b1b1b' : '#f0f0f0';
    boostBorder = `
      linear-gradient(${avatarBackgroundColor} 0 0) padding-box, linear-gradient(${boost.color}, ${boost.color}) border-box
    `;
  }

  const getIcon = () => {
    if (borderType === 'scorcher') {
      return (
        <div className={styles.iconWrapper}>
          <Icon
            className={styles.icon}
            name="pepper"
            customStyles={boost ? { fill: boost.textColor } : null}
          />
        </div>
      );
    }

    if (borderType === 'boost') {
      return (
        <div className={styles.iconWrapper} style={{ background: boost.color }}>
          <Icon
            className={styles.icon}
            name={boost.icon}
            customStyles={boost ? { fill: boost.textColor } : null}
          />
        </div>
      );
    }

    if (borderType === 'powerUp') {
      return (
        <div className={styles.iconWrapper}>
          <Icon className={styles.icon} name={powerUp.icon} />
        </div>
      );
    }

    if (iconText) {
      return (
        <div className={clsx(styles.iconWrapper, styles.iconText)}>
          <span>{iconText}</span>
        </div>
      );
    }

    return null;
  };

  return (
    <div
      className={clsx(styles.playerDetailedAvatar, classNames?.avatar, {
        [styles.circleStripes]: circleStripes,
        [styles.backgroundStripes]: backgroundStripes,
      })}
    >
      {backgroundStripes && (
        <>
          <div
            className={clsx(styles.backgroundStripe, styles.secondaryStripe)}
            style={{ backgroundColor: secondaryColor }}
          />
          <div
            className={clsx(styles.backgroundStripe, styles.primaryStripe)}
            style={{ backgroundColor: primaryColor }}
          />
        </>
      )}
      <div
        className={clsx(styles.avatarWrapper, styles[size], {
          [styles.scorcher]: borderType === 'scorcher',
          [styles.boost]: borderType === 'boost',
          [styles.primary]: borderType === 'primary',
          [styles.transparent]: borderType === 'transparent',
          [styles.powerUp]: borderType === 'powerUp',
        })}
      >
        <div
          className={styles.avatarBorder}
          style={borderType === 'boost' ? { background: boostBorder } : {}}
        >
          {circleStripes && (
            <>
              <div
                className={clsx(styles.circleStripe, styles.primaryStripe)}
                style={{ backgroundColor: primaryColor }}
              />
              <div
                className={clsx(styles.circleStripe, styles.secondaryStripe)}
                style={{ backgroundColor: secondaryColor }}
              />
            </>
          )}
          <Avatar
            url={player.imageUrl}
            customPlaceholder="placeholderPlayer"
            classNames={{
              avatar: clsx(styles.avatar, { [styles.placeholderImage]: !player.imageUrl }),
            }}
          />
        </div>
        {getIcon()}
      </div>
    </div>
  );
};

export default PlayerDetailedAvatar;
