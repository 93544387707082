import React from 'react';
import clsx from 'clsx';

import Icon, { IconTypes } from '@/components/atoms/icon';

import badges from './badges';

import styles from './styles.scss';

interface BadgeProps {
  icon?: IconTypes;
  className?: string;
  badge?: keyof typeof badges;
  letter?: string;
  size?: 'small' | 'large';
}

const Badge = ({ badge, className, icon, letter, size = 'large' }: BadgeProps) => {
  const resolvedBadge = badge ? badges[badge] : undefined;

  const colorClass = resolvedBadge ? styles[resolvedBadge.color] : '';
  const iconName = resolvedBadge ? resolvedBadge.icon : icon;
  const text = resolvedBadge ? resolvedBadge.text : letter;
  const title = resolvedBadge ? resolvedBadge.title : '';

  const fontStyle = text && text.length === 1 ? styles.normalText : styles.smallText;

  const content = iconName ? <Icon name={iconName} /> : <p className={fontStyle}>{text}</p>;

  return (
    <div
      data-testid="badge"
      className={clsx(styles.badge, colorClass, className, { [styles[size]]: size })}
      title={title}
    >
      {content}
    </div>
  );
};

export default Badge;
